<template>
  <v-menu
    v-model="show"
    :close-on-content-click="false"
    offset-y
  >
    <template v-slot:activator="{ on:onMenu, attrs: attrsMenu}">
      <v-tooltip top>
        <template v-slot:activator="{on:onTooltip, attrs: attrsTooltip}">
          <div
            v-bind="{...attrsTooltip}"
            class="d-inline-block"
            v-on="isDisabled?{...onTooltip}:null"
          >
            <v-btn
              color="primary"
              class="mr-2"
              dark
              dense
              :loading="loading"
              v-bind="{...attrsMenu}"
              :disabled="isDisabled"
              v-on="{...onMenu}"
            >
              Bulk tasks
              <v-icon right>
                {{ icons.mdiDotsVertical }}
              </v-icon>
            </v-btn>
          </div>
        </template>
        <span>Select some account</span>
      </v-tooltip>
    </template>
    <v-list>
      <v-list-group>
        <template v-slot:activator>
          <v-list-item-title>Profile actions</v-list-item-title>
        </template>
        <v-list-item
          v-for="action in menus.profileActions"
          :key="action.event"
          class="ml-4"
          @click="emit(action.event)"
        >
          <v-list-item-title>{{ action.label }}</v-list-item-title>
        </v-list-item>
      </v-list-group>
      <v-list-group>
        <template v-slot:activator>
          <v-list-item-title>Change Parameter</v-list-item-title>
        </template>
        <v-list-item
          v-for="action in menus.changeParams"
          :key="action.event"
          class="ml-4"
          @click="emit(action.event)"
        >
          <v-list-item-title>{{ action.label }}</v-list-item-title>
        </v-list-item>
      </v-list-group>
      <v-list-item
        @click="emit('leaveGuild')"
      >
        <v-list-item-title>Leave guild</v-list-item-title>
      </v-list-item>
      <v-divider />
      <v-list-item
        class="error--text"
        @click="emit('delete')"
      >
        <v-list-item-action>
          <v-icon color="error">
            {{ icons.mdiDeleteForever }}
          </v-icon>
        </v-list-item-action>
        <v-list-item-title>Delete</v-list-item-title>
      </v-list-item>
    </v-list>
  </v-menu>
</template>

<script>
import { mdiDeleteForever, mdiPencil, mdiDotsVertical } from '@mdi/js'

export default {
  props: {
    loading: Boolean,
    isDisabled: {
      type: Boolean,
      default: false,
    },
  },

  data: () => ({
    show: false,
    icons: {
      mdiDeleteForever,
      mdiDotsVertical,
      mdiPencil,
    },
    menus: {
      profileActions: [{
        label: 'Change Nickname',
        event: 'changeNickname',
      }, {
        label: 'Change Bio',
        event: 'changeBio',
      }, {
        label: 'Change Avatar',
        event: 'changeAvatar',
      }, {
        label: 'Change Profile Color',
        event: 'changeProfileColor',
      }, {
        label: 'Change Banner',
        event: 'changeBanner',
      }, {
        label: 'Change Password',
        event: 'changePassword',
      }, {
        label: 'Change Discriminator',
        event: 'changeDiscriminator',
      }],
      changeParams: [{
        label: 'Type',
        event: 'changeType',
      }, {
        label: 'Proxy',
        event: 'changeProxy',
      }, {
        label: 'Project',
        event: 'changeProject',
      },
      {
        label: 'Tag',
        event: 'changeTag',
      }],
    },
  }),

  methods: {
    emit(event) {
      this.show = false
      this.$emit(event)
      this.$emit('select', event)
    },
  },
}
</script>

<style>

</style>
