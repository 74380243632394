<template>
  <v-menu offset-y>
    <template v-slot:activator="{ on, attrs }">
      <v-btn
        color="primary"
        dark
        icon
        :loading="loading"
        v-bind="attrs"
        v-on="on"
      >
        <v-icon>{{ icons.mdiDotsVertical }}</v-icon>
      </v-btn>
    </template>
    <v-list dense>
      <v-list-item @click="emit('edit')">
        <v-list-item-action>
          <v-icon>{{ icons.mdiPencil }}</v-icon>
        </v-list-item-action>
        <v-list-item-title>Edit</v-list-item-title>
      </v-list-item>
      <v-list-item @click="emit('copy')">
        <v-list-item-action>
          <v-icon>{{ icons.mdiContentCopy }}</v-icon>
        </v-list-item-action>
        <v-list-item-title>Copy</v-list-item-title>
      </v-list-item>
      <v-divider />
      <v-list-item
        class="error--text"
        @click="emit('delete')"
      >
        <v-list-item-action>
          <v-icon color="error">
            {{ icons.mdiDeleteForever }}
          </v-icon>
        </v-list-item-action>
        <v-list-item-title>{{ deleteMsg || 'Delete' }} </v-list-item-title>
      </v-list-item>
    </v-list>
  </v-menu>
</template>

<script>
import {
  mdiDeleteForever, mdiPencil, mdiDotsVertical, mdiContentCopy,
} from '@mdi/js'

export default {
  props: {
    loading: Boolean,
    deleteMsg: { type: String, default: '' },
  },

  data: () => ({
    icons: {
      mdiDeleteForever,
      mdiContentCopy,
      mdiDotsVertical,
      mdiPencil,
    },
  }),

  methods: {
    emit(event) {
      this.$emit(event)
    },
  },
}
</script>

<style>

</style>
