<template>
  <v-dialog
    v-model="isOpen"
    width="90vw"
    persistent
    @click:outside="tryClose"
  >
    <v-card>
      <v-card-title>
        Selected accounts
        <v-spacer />
        <v-btn
          icon
          @click="tryClose"
        >
          <v-icon>{{ icons.mdiClose }}</v-icon>
        </v-btn>
      </v-card-title>
      <v-divider />
      <v-card-text>
        <div class="d-flex my-2 align-center justify-space-between">
          <TableSearch
            v-model="tableOptions.search"

            :show-filters.sync="showFilters"

            :check-filter-toggle="checkFilterToggle"
          />
        </div>
        <v-data-table
          :items="filteredItems"
          :headers="isDetails?projectAccountsHeaders:accountHeaders"
          hide-default-footer
          :search="tableOptions.search"
          show-expand
          :options.sync="tableOptions"
          class="mt-2"
          :custom-filter="customSearch"
          :footer-props="{
            'items-per-page-options': pages,
            'show-first-last-page': true,
            'show-current-page': true,
          }"
        >
          <template
            v-slot:top
          >
            <v-layout
              v-show="showFilters"
              class="pa-4 pt-0"
            >
              <v-row v-if="!isDetails">
                <v-col cols="3">
                  <v-range-slider
                    v-model="projectRange"
                    thumb-label
                    :height="42"
                    class="mt-4"
                    :step="1"
                    :min="0"
                    :max="selectedItems.length"
                    dense
                    hide-details
                    label="Project Count"
                  ></v-range-slider>
                  <SelectProject
                    v-model="tableOptions.project"
                    label="Projects"
                    placeholder="Select projects(s)"
                    multiple
                    hide-details
                    :is-filter="true"
                  ></SelectProject>
                  <SelectTag
                    v-model="tableOptions.tags"
                    multiple
                  />
                </v-col>
                <v-col cols="3">
                  <v-select
                    v-model="tableOptions.status"
                    label="Status"
                    placeholder="Select status"
                    hide-details
                    multiple
                    chips
                    :items="statuses"
                    clearable
                  >
                  </v-select>
                  <v-select
                    v-model="tableOptions.verification"
                    label="Verification"
                    placeholder="Select verification"
                    multiple
                    chips
                    hide-details
                    :items="verifications"
                    clearable
                  ></v-select>
                </v-col>
                <v-col cols="3">
                  <v-select
                    v-model="tableOptions.type"
                    label="Types"
                    placeholder="Select type(s)"
                    multiple
                    chips
                    hide-details
                    :ripple="false"
                    :items="types"
                    clearable
                  >
                    <template v-slot:item="data">
                      <v-simple-checkbox
                        :value="data.attrs['aria-selected'] === 'true'"
                      />
                      <v-list-item-icon class="mr-2">
                        <v-icon v-text="typeIcons[data.item.value]"></v-icon>
                      </v-list-item-icon>
                      <v-list-item-content>
                        <v-list-item-title>{{ data.item.text }}</v-list-item-title>
                      </v-list-item-content>
                    </template>
                  </v-select>
                  <SelectProxy
                    v-model="tableOptions.proxy"
                    label="Proxy"
                    placeholder="Select proxy"
                    hide-details
                    multiple
                    :is-filter="true"
                  ></SelectProxy>
                </v-col>
                <v-col cols="3">
                  <v-checkbox
                    v-model="tableOptions.nitro"
                    hide-details
                    label="Nitro"
                  />
                  <v-checkbox
                    v-model="tableOptions.spam"
                    hide-details
                    label="Spam"
                  />
                </v-col>
              </v-row>
              <v-row v-if="isDetails">
                <v-col cols="3">
                  <v-select
                    v-model="tableOptions.status"
                    label="Status"
                    placeholder="Select status"
                    multiple
                    hide-details
                    :items="statuses"
                    clearable
                  ></v-select>
                  <SelectTag
                    v-model="tableOptions.tags"
                    multiple
                  />
                </v-col>
                <v-col cols="3">
                  <v-select
                    v-model="tableOptions.type"
                    multiple
                    label="Types"
                    placeholder="Select type"
                    hide-details
                    :items="types"
                    clearable
                  ></v-select>
                  <SelectProxy
                    v-model="tableOptions.proxy"
                    label="Proxy"
                    placeholder="Select proxy"
                    multiple
                    hide-details
                    :is-filter="true"
                  ></SelectProxy>
                </v-col>
                <v-col cols="3">
                  <v-select
                    v-model="tableOptions.role"
                    multiple
                    label="Roles"
                    placeholder="Select role"
                    hide-details
                    :items="roles"
                    clearable
                  ></v-select>
                </v-col>
                <v-col cols="3">
                  <v-select
                    v-model="tableOptions.project_status"
                    multiple
                    label="Project statuses"
                    placeholder="Select status"
                    hide-details
                    :items="project_statuses"
                    clearable
                  ></v-select>
                </v-col>
              </v-row>
            </v-layout>
          </template>

          <template v-slot:item.numbering="{ index }">
            {{ getNumbering(index) }}.
          </template>
          <template v-slot:expanded-item="{ headers, item }">
            <td colspan="2"></td>
            <td
              class="pa-4"
              :colspan="headers.length-2"
            >
              <AccountExpand
                :account="item"
              />
            </td>
          </template>
          <template v-slot:item.status="{ item, value }">
            <div
              class="d-flex align-center"
            >
              <v-tooltip
                right
              >
                <template v-slot:activator="{ on, attrs }">
                  <v-icon
                    v-if="value === 'active'"
                    class="mr-4"
                    color="success"
                    v-bind="attrs"
                    v-on="on"
                  >
                    {{ icons.mdiCheckCircleOutline }}
                  </v-icon>
                  <v-icon
                    v-else-if="value === 'locked'"
                    v-bind="attrs"
                    class="mr-4"
                    color="warning"
                    v-on="on"
                  >
                    {{ icons.mdiLockOutline }}
                  </v-icon>
                  <v-icon
                    v-else-if="value === 'banned'"
                    v-bind="attrs"
                    class="mr-4"
                    color="error"
                    v-on="on"
                  >
                    {{ icons.mdiCancel }}
                  </v-icon>
                </template>
                <span>{{ value }}</span>
              </v-tooltip>
            </div>
          </template>
          <template v-slot:item.username="{ item, value }">
            <span dir="ltr">{{ value }}</span>
            <span v-show="item.discriminator">#{{ item.discriminator }}</span>
          </template>
          <template v-slot:item.verification="{ item }">
            <div class="d-flex align-center">
              <div class="d-flex flex-column align-start pa-2">
                <v-chip
                  v-if="item.spammer"
                  label
                  small
                  text-color="white"
                  color="warning"
                  class="mb-2"
                >
                  Spammer
                </v-chip>
                <v-chip
                  v-if="item.nitro"
                  label
                  class="mb-2"
                  small
                  text-color="white"
                  color="info"
                >
                  Nitro
                </v-chip>
                <v-chip
                  v-if="!item.proxy"
                  label
                  small
                  text-color="white"
                  color="error"
                  class="mb-2"
                >
                  No proxy
                </v-chip>
                <v-chip
                  v-if=" item.limited_access"
                  label
                  small
                  text-color="white"
                  color="teal"
                  class="mb-2"
                >
                  Limited access
                </v-chip>
                <v-tooltip
                  v-if="item.verification"
                  right
                >
                  <template
                    v-slot:activator="{ on, attrs }"
                  >
                    <v-chip
                      label
                      small
                      text-color="white"
                      color="primary"
                      v-bind="attrs"
                      v-on="on"
                    >
                      Verification:
                      <v-icon
                        small
                        right
                      >
                        {{ verificationIcons[item.verification] }}
                      </v-icon>
                    </v-chip>
                  </template>
                  <span>{{ item.verification }}</span>
                </v-tooltip>
              </div>
            </div>
          </template>
          <template v-slot:item.type="{ value }">
            <v-tooltip
              v-for="item in value"
              :key="item"
              bottom
            >
              <template v-slot:activator="{ on, attrs }">
                <v-icon
                  v-bind="attrs"
                  v-on="on"
                >
                  {{ typeIcons[item] || item }}
                </v-icon>
              </template>
              <span>{{ item }}</span>
            </v-tooltip>
          </template>
          <template v-slot:item.projects="{item, value }">
            <template v-if="value&&value.length > 5">
              <v-chip
                v-for="el in value.slice(0,item.projectsCount)"
                :key="el.id"
                class="ma-2"
                role="button"
                :to="{name: 'discordProjectDetails', params: {id: el.id}}"
              >
                {{ el.name }}
              </v-chip>
              <v-chip
                color="primary"
                class="ma-2"
                @click="()=>{
                  item.isShowMoreProjects=!item.isShowMoreProjects
                  item.projectsCount=item.isShowMoreProjects?value.length:3
                }"
              >
                {{ item.isShowMoreProjects?'Hide':`${value.length-3} more` }}
              </v-chip>
            </template>
            <template v-else>
              <v-chip
                v-for="el in value"
                :key="el.id"
                class="ma-1"
                role="button"
                :to="{name: 'discordProjectDetails', params: {id: el.id}}"
              >
                {{ el.name }}
              </v-chip>
            </template>
          </template>
          <template v-slot:item.tags="{item, value }">
            <template v-if="value&&value.length > 5">
              <v-chip
                v-for="el in value.slice(0, item.tagsCount)"
                :key="el.id"
                class="ma-2"
                role="button"
              >
                {{ el }}
              </v-chip>
              <v-chip
                class="ma-2"
                color="primary"
                @click="()=>{
                  item.isShowMoreTags=!item.isShowMoreTags
                  item.tagsCount=item.isShowMoreTags?value.length:3
                }"
              >
                {{ item.isShowMoreTags?'Hide':`${value.length-3} more` }}
              </v-chip>
            </template>
            <template v-else>
              <v-chip
                v-for="el in value"
                :key="el.id"
                class="ma-2"
                role="button"
              >
                {{ el }}
              </v-chip>
            </template>
          </template>
          <template v-slot:item.project_status="{value}">
            <v-chip
              v-if="value"
              :color="projectAccountStatuses[value]&&projectAccountStatuses[value].color"
            >
              {{ projectAccountStatuses[value]&&projectAccountStatuses[value].text }}
            </v-chip>
          </template>
          <template v-slot:item.roles="{ value }">
            <template v-if="roles">
              <v-chip
                v-for="role in value"
                :key="role"
              >
                {{ roles.find(r => r.value === role).text }}
              </v-chip>
            </template>
          </template>
          <template v-slot:item.action="{ item}">
            <v-btn
              icon
              class="ml-2"
              @click="$emit('unselect',item.id)"
            >
              <v-icon dense>
                {{ icons.mdiClose }}
              </v-icon>
            </v-btn>
          </template>
          <template v-slot:item.mobile="{ value }">
            <TrueFalse :value="value"></TrueFalse>
          </template>
          <template v-slot:footer="{ props, on }">
            <TableFooter
              v-bind="props"
              v-on="on"
            />
          </template>
        </v-data-table>
      </v-card-text>
    </v-card>

    <!--
      CONFIRM CANCEL UPDATE
    -->
    <ConfirmDialog
      ref="updateConfirm"
      :width="400"
      :z-index="99"
    >
      <template v-slot:default="{agree, cancel}">
        <v-card>
          <v-card-title>Confirm update cancel</v-card-title>
          <v-card-text>
            You have unsaved changes, you wanna leave
          </v-card-text>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn
              outlined
              @click="agree"
            >
              Agree
            </v-btn>
            <v-btn
              color="primary"
              @click="cancel"
            >
              Cancel
            </v-btn>
          </v-card-actions>
        </v-card>
      </template>
    </ConfirmDialog>
  </v-dialog>
</template>

<script>
import {
  mdiClose, mdiAccount, mdiCheckCircleOutline, mdiLockOutline,
  mdiCellphoneKey, mdiEmailCheckOutline, mdiShieldOffOutline,
  mdiCloseOctagonOutline, mdiLinkVariantPlus, mdiGiftOpenOutline,
  mdiFormatListBulletedSquare, mdiCancel, mdiFilter, mdiMagnify,
} from '@mdi/js'
import _cloneDeep from 'lodash/cloneDeep'
import {
  projectAccountStatuses,
  statuses, verifications, types,
} from '@core/constants'
import TrueFalse from '@/components/TrueFalse.vue'
import ConfirmDialog from '../../ConfirmDialog.vue'
import TableFooter from '@/components/TableFooter.vue'
import SelectProject from '@/components/discord/project/SelectProject.vue'
import { castToArray } from '@/@core/utils'
import TableSearch from '@/components/TableSearch.vue'
import SelectTag from '@/components/discord/account/SelectTag.vue'
import SelectProxy from '@/components/proxy/SelectProxy.vue'
import AccountExpand from '@/components/discord/account/AccountExpand.vue'

const defaultOptions = {
  project_count_min: 0,
  project_count_max: 0,
  spam: false,
  nitro: false,
  type: castToArray(null),
  proxy: castToArray(null),
  status: castToArray(null),
  verification: castToArray(null),
  project: castToArray(null),
  tags: castToArray(null),
  search: '',
  sortBy: [],
  sortDesc: [],
  page: 1,
  itemsPerPage: 50,
  project_status: [],
  role: [],
}

export default {
  components: {
    ConfirmDialog, TrueFalse, TableFooter, SelectProject, AccountExpand, TableSearch, SelectTag, SelectProxy,
  },
  props: {
    selectedItems: {
      type: Array,
      default: () => ({}),
    },
    roles: {
      type: Array,
      default: () => (null),
    },
    isDetails: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      statuses,
      verifications,
      types,
      icons: {
        mdiClose,
        mdiAccount,
        mdiCheckCircleOutline,
        mdiLockOutline,
        mdiCancel,
        mdiFilter,
        mdiMagnify,
      },
      isOpen: false,
      verificationIcons: {
        'phone verified': mdiCellphoneKey,
        'mail verified': mdiEmailCheckOutline,
        'non verified': mdiShieldOffOutline,
        null: mdiCloseOctagonOutline,
      },
      pages: [50, 100, 200],
      showFilters: false,
      projectRange: [
        0,
        0,
      ],
      accountHeaders: [
        {
          cellClass: 'pa-0 text-center',
          value: 'numbering',
          width: '50px',
          align: 'center',
          sortable: false,
        },
        {
          text: '',
          class: 'pa-0 pr-4',
          cellClass: 'pa-0 pr-4',
          value: 'data-table-expand',
        },
        {
          text: 'Status',
          value: 'status',
          width: '90px',
          sortable: true,
          filterable: true,
        },
        {
          text: 'Username',
          value: 'username',
          sortable: true,
          filterable: true,
        },
        {
          text: 'Type',
          value: 'type',
          sortable: true,
          filterable: true,
          width: '104px',
        },
        {
          text: 'Projects',
          value: 'projects',
          sortable: true,
          filterable: true,
          width: '20%',
        },
        {
          text: 'Tags',
          value: 'tags',
          sortable: true,
          filterable: true,
          width: '20%',
        },
        {
          text: 'Status',
          value: 'verification',
          sortable: true,
          filterable: true,
        },
        {
          align: 'end',
          value: 'action',
          sortable: false,
        },
      ],
      projectAccountsHeaders: [
        {
          cellClass: 'pa-0 text-center',
          value: 'numbering',
          width: '50px',
          align: 'center',
          sortable: false,
        },
        {
          text: '',
          class: 'pa-0 pr-4',
          cellClass: 'pa-0 pr-4',
          value: 'data-table-expand',
        },
        {
          text: 'Status',
          value: 'status',
          width: '90px',
          sortable: true,
          filterable: true,
        },
        {
          text: 'Username',
          value: 'username',
          sortable: true,
          filterable: true,
        },
        {
          text: 'Type',
          value: 'type',
          sortable: true,
          filterable: true,
          width: '104px',
        },
        {
          text: 'Tags',
          value: 'tags',
          sortable: true,
          filterable: true,
          width: '100px',
        },
        {
          value: 'roles',
          text: 'Roles',
          width: '100px',
        },
        {
          value: 'project_status',
          text: 'Project Status',
          width: '135px',
        },
        {
          align: 'end',
          value: 'action',
          sortable: false,
        },
      ],
      tableOptions: {
        project_count_min: 0,
        project_count_max: 0,
        spam: false,
        nitro: false,
        type: castToArray(null),
        proxy: castToArray(null),
        status: castToArray(null),
        verification: castToArray(null),
        project: castToArray(null),
        tags: castToArray(null),
        search: '',
        sortBy: [],
        sortDesc: [],
        page: 1,
        itemsPerPage: localStorage.getItem('itemsPerPage') || 50,
        project_status: [],
        role: [],
      },
      typeIcons: {
        referral: mdiLinkVariantPlus,
        giveaway: mdiGiftOpenOutline,
        'white list': mdiFormatListBulletedSquare,
      },
      projectAccountStatuses,
      filtered: [],
    }
  },
  computed: {
    filteredItems() {
      let filtered = _cloneDeep(this.selectedItems)
      if (this.tableOptions.project && this.tableOptions.project.length > 0) {
        filtered = this.checkItems(filtered, 'projects', this.tableOptions.project)
      }
      if (this.tableOptions.tags && this.tableOptions.tags.length > 0) {
        filtered = this.checkItems(filtered, 'tags', this.tableOptions.tags)
      }
      if (this.tableOptions.type && this.tableOptions.type.length > 0) {
        filtered = this.checkItems(filtered, 'type', this.tableOptions.type)
      }
      if (this.tableOptions.proxy && this.tableOptions.proxy.length > 0) {
        filtered = filtered.filter(el => {
          if (this.tableOptions.proxy.includes('null')) {
            return !el.proxy
          }

          return this.tableOptions.proxy.includes(el.proxy.id)
        })
      }
      if (this.tableOptions.status && this.tableOptions.status.length > 0) {
        filtered = filtered.filter(el => this.tableOptions.status.includes(el.status))
      }
      if (this.tableOptions.verification && this.tableOptions.verification.length > 0) {
        filtered = filtered.filter(el => this.tableOptions.verification.includes(el.verification))
      }
      if (this.projectRange[0] >= 0 && this.projectRange[1] > 0) {
        filtered = filtered.filter(el => {
          if (el.projects.length >= this.projectRange[0] && el.projects.length <= this.projectRange[1]) {
            return true
          }

          return false
        })
      }
      if (this.tableOptions.spam) {
        filtered = filtered.filter(el => el.spam)
      }
      if (this.tableOptions.nitro) {
        filtered = filtered.filter(el => el.nitro)
      }

      // project details
      if (this.tableOptions.role && this.tableOptions.role.length > 0) {
        filtered = this.checkItems(filtered, 'roles', this.tableOptions.role)
      }
      if (this.tableOptions.project_status && this.tableOptions.project_status.length > 0) {
        filtered = filtered.filter(el => this.tableOptions.project_status.includes(el.project_status))
      }

      return filtered
    },
    project_statuses() {
      return Object.entries(projectAccountStatuses).map(([key, value]) => ({ text: value.text, value: key }))
    },
  },
  methods: {
    open() {
      this.isOpen = true
    },
    reset() {
      this.isOpen = false
      this.showFilters = false
      this.tableOptions = {
        ...defaultOptions,
      }
    },
    async tryClose() {
      if (this.isChanged && await this.$refs.updateConfirm.open()) {
        this.reset()
      } else this.reset()
    },
    checkFilterToggle() {
      const filters = _cloneDeep(this.tableOptions)
      const tableKeys = ['search', 'sortBy', 'sortDesc', 'page', 'itemsPerPage', 'groupBy', 'groupDesc', 'multiSort', 'mustSort']
      let filtersStatus = false
      Object.entries(filters).forEach(([key, value]) => {
        if (tableKeys.includes(key)) {
          delete filters[key]
        } else if ((!!value !== false && !Array.isArray(value)) || (value?.length && value?.length > 0)) {
          filtersStatus = true
        }
      })

      return filtersStatus
    },
    checkItems(items, itemsKey, forCheck) {
      return items.filter(el => {
        let isTrue = false
        el[itemsKey].forEach(proj => {
          if (forCheck.includes(itemsKey === 'projects' ? proj.id : proj)) {
            isTrue = true
          }
        })

        return isTrue
      })
    },
    getNumbering(index) {
      return (this.tableOptions.page - 1) * this.tableOptions.itemsPerPage + (index + 1)
    },
    customSearch(val, search, item) {
      return Object.entries(item).some(([key, value]) => {
        if (key === 'token' || key === 'seller' || key === 'login' || key === 'username') {
          return value && value.toLowerCase().includes(search.toLowerCase())
        }

        return false
      })
    },
  },
}
</script>

<style scoped>
label span{
  font-size: 0.7rem;
}
</style>
