<template>
  <div class="text-end">
    <v-bottom-sheet
      ref="bottom-class"
      v-model="sheet"
      scrollable
    >
      <template v-slot:activator="{ on, attrs }">
        <v-btn
          color="primary"
          :attrs="attrs"
          :elevation="12"
          fab
          v-on="on"
        >
          <v-icon>{{ mdiPlus }}</v-icon>
        </v-btn>
      </template>
      <AddAccountForm
        v-if="sheet"
        :loading="loading"
        :is-project-details="isProjectDetails"
        :project-id="projectId"
        @submit="submit"
      />
    </v-bottom-sheet>
  </div>
</template>

<script>
import { mdiPlus } from '@mdi/js'

import store from '@/store'

import AddAccountForm from './AddForm.vue'
import { DISCORD_MODULE_NAME } from '@/store/modules/discordStoreModule'

export default {
  components: { AddAccountForm },
  props: {
    isProjectDetails: {
      type: Boolean,
      default: false,
    },
    projectId: {
      type: Number,
      default: null,
    },
  },
  data: () => ({
    mdiPlus,
    sheet: false,
    loading: false,
  }),
  methods: {
    async submit(account) {
      this.loading = true
      try {
        const { data } = await store.dispatch(`${DISCORD_MODULE_NAME}/addAccount`, { account })

        this.sheet = false
        this.loading = false
        this.$emit('submitted')

        this.$message.success(data.message || 'Account added successfully')
      } catch (err) {
        this.sheet = false
        this.loading = false
        this.$message.error(err)
      }
    },
  },
}
</script>
