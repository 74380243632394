<template>
  <v-dialog
    v-model="isOpen"
    width="90vw"
    persistent
    @click:outside="tryClose"
  >
    <v-card>
      <v-card-title>
        Export accounts
        <v-spacer />
        <v-btn
          icon
          @click="tryClose"
        >
          <v-icon>{{ icons.mdiClose }}</v-icon>
        </v-btn>
      </v-card-title>
      <v-card-text>
        <v-row>
          <v-col>
            <label>
              Select columns to export
            </label>

            <v-checkbox
              v-for="column in tableColumns"
              :key="column.value"
              v-model="fields_to_export"
              :value="column.value"
              :label="column.label"
            />
            <v-checkbox
              v-model="fields_to_export"
              value="discriminator"
              label="Discriminator"
              :disabled="!fields_to_export.includes('username')"
            />
            <v-row>
              <v-col
                cols="12"
              >
                <v-checkbox
                  v-model="fields_to_export"
                  value="proxy"
                  label="Proxy"
                />
                <v-select
                  v-if="fields_to_export.includes('proxy')"
                  v-model="parseFormat"
                  label="Format"
                  class="mb-2"
                  hide-details="auto"
                  :items="urlFormats"
                />
              </v-col>
            </v-row>
            <v-radio-group
              v-model="exportFormat"
              label="Select export format:"
            >
              <v-radio
                label="csv"
                value="csv"
              ></v-radio>
              <v-radio
                label="txt"
                value="txt"
              ></v-radio>
            </v-radio-group>
          </v-col>
        </v-row>
      </v-card-text>

      <v-divider />

      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn
          color="primary"
          :loading="loading"
          @click="submit"
        >
          Export
        </v-btn>
        <v-btn @click="tryClose">
          Cancel
        </v-btn>
      </v-card-actions>
    </v-card>

    <!--
      CONFIRM CANCEL UPDATE
    -->
    <ConfirmDialog
      ref="updateConfirm"
      :width="400"
      :z-index="99"
    >
      <template v-slot:default="{agree, cancel}">
        <v-card>
          <v-card-title>Confirm update cancel</v-card-title>
          <v-card-text>
            You have unsaved changes, you wanna leave
          </v-card-text>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn
              outlined
              @click="agree"
            >
              Agree
            </v-btn>
            <v-btn
              color="primary"
              @click="cancel"
            >
              Cancel
            </v-btn>
          </v-card-actions>
        </v-card>
      </template>
    </ConfirmDialog>
  </v-dialog>
</template>

<script>
import _pick from 'lodash/pick'
import { mdiClose, mdiCheck, mdiAlertCircleOutline } from '@mdi/js'
import DiscordApi from '@core/api/discord'
import ConfirmDialog from './ConfirmDialog.vue'

export default {
  components: { ConfirmDialog },
  props: {
    selectedAccounts: {
      type: Array,
      default: () => ({}),
    },
    isAllSelected: {
      type: Boolean,
      default: false,
    },
    filters: {
      type: Object,
      default: () => ({}),
    },
    projectsTotal: {
      type: Number,
      default: 0,
    },
  },
  data: () => ({
    icons: {
      mdiClose,
      mdiCheck,
      mdiAlertCircleOutline,
    },
    isOpen: false,
    loading: false,
    exportFormat: 'csv',
    fields_to_export: ['token'],
    urlFormats: [
      'ip:port:login:password',
      'ip:port@login:password',
      'ip:port|login:password',
      'login:password@ip:port',
      'login:password:ip:port',
      'ip:port',
    ],
    parseFormat: '',
    tableColumns: [{ value: 'token', label: 'Token' },
      { value: 'login', label: 'Login' },
      { value: 'username', label: 'Username' },
      { value: 'password', label: 'Password' },
      { value: 'discord_user_id', label: 'Discord user id' }],
  }),
  computed: {
    filteredFields() {
      if (this.fields_to_export.includes('login') && this.fields_to_export.includes('password') && this.fields_to_export.includes('token')) {
        const newArr = [...this.fields_to_export]

        return ['login', 'password', 'token'].concat(newArr.filter(item => item !== 'login' && item !== 'password' && item !== 'token'))
      }

      return this.fields_to_export
    },
  },
  methods: {
    async submit() {
      try {
        this.loading = true
        await this.exportAccounts()
      } catch (e) {
        this.$message.error(e)
      }
      this.loading = false
    },

    async exportAccounts() {
      let accounts
      if (this.isAllSelected) {
        const { data } = await DiscordApi.Account.fetchAccounts({ ...this.filters, limit: this.projectsTotal })
        accounts = data.items
      } else {
        accounts = this.selectedAccounts
      }

      if (this.exportFormat === 'csv') {
        this.createCsv(accounts)
      } else {
        this.createTxt(accounts)
      }
    },
    createCsv(accounts) {
      const rows = this.checkDiscriminator(accounts)
        .map(acc => Object
          .values(_pick(acc, this.filteredFields))
          .map(v => {
            if (typeof v === 'object') {
              if (!v) {
                return ''
              }

              return `"${this.changeProxyFormat(v.value)}"`
            }

            return JSON.stringify(v)
          })
          .join(','))
        .join('\n')

      this.exportDownload(rows, 'text/csv', 'accounts.csv')
    },

    createTxt(accounts) {
      const rows = this.checkDiscriminator(accounts)
        .map(acc => Object
          .values(_pick(acc, this.filteredFields))
          .map(v => {
            if (typeof v === 'object') {
              if (!v) {
                return ''
              }

              return this.changeProxyFormat(v.value)
            }

            return JSON.stringify(v).replaceAll('"', '')
          })
          .join(':'))
        .join('\n')
      this.exportDownload(rows, 'text/plain', 'accounts.txt')
    },
    checkDiscriminator(accounts) {
      if (this.fields_to_export.includes('username') && this.fields_to_export.includes('discriminator')) {
        return accounts.map(item => {
          let acc = item
          if (item.username && item.discriminator) {
            acc = { ...item, username: `${item.username}#${item.discriminator}` }
            delete acc.discriminator
          }

          return acc
        })
      }

      return accounts
    },
    open() {
      this.isOpen = true
    },
    reset() {
      this.isOpen = false
      this.proxy = null
    },
    async tryClose() {
      if (this.isChanged && await this.$refs.updateConfirm.open()) {
        this.reset()
      } else this.reset()
    },
    exportDownload(content, type, filename) {
      const blob = new Blob([content], { type })
      if (window.navigator.msSaveOrOpenBlob) {
        window.navigator.msSaveBlob(blob, filename)
      } else {
        const elem = window.document.createElement('a')
        elem.href = window.URL.createObjectURL(blob)
        elem.download = filename
        document.body.appendChild(elem)
        elem.click()
        document.body.removeChild(elem)
      }
    },
    changeProxyFormat(proxyUrl) {
      const proxy = proxyUrl.replace(/\/\//gi, '') || ''
      const match = proxy.match(/(?<protocol>.*):(?<login>.*):(?<password>.*)@(?<ip>.*):(?<port>.*)/)
      let proxyToExport = ''

      switch (this.parseFormat) {
        case ('ip:port:login:password'):
          proxyToExport = `${match?.groups?.protocol}://${match?.groups?.ip}:${match?.groups?.port}:${match?.groups?.login}:${match?.groups?.password}`
          break
        case ('ip:port@login:password'):
          proxyToExport = `${match?.groups?.protocol}://${match?.groups?.ip}:${match?.groups?.port}@${match?.groups?.login}:${match?.groups?.password}`
          break
        case ('ip:port|login:password'):
          proxyToExport = `${match?.groups?.protocol}://${match?.groups?.ip}:${match?.groups?.port}|${match?.groups?.login}:${match?.groups?.password}`
          break
        case ('login:password:ip:port'):
          proxyToExport = `${match?.groups?.protocol}://${match?.groups?.login}:${match?.groups?.password}:${match?.groups?.ip}:${match?.groups?.port}`
          break
        case ('login:password@ip:port'):
          proxyToExport = `${match?.groups?.protocol}://${match?.groups?.login}:${match?.groups?.password}@${match?.groups?.ip}:${match?.groups?.port}`
          break
        case ('ip:port'):
          proxyToExport = `${match?.groups?.protocol}://${match?.groups?.ip}:${match?.groups?.port}`
          break
        default:
          proxyToExport = proxyUrl
      }

      return proxyToExport
    },

  },
}
</script>
