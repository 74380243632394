<template>
  <v-autocomplete
    v-model="localValue"
    deletable-chips
    :loading="loading"
    :disabled="disabled"
    :items="localItems"
    chips
    :search-input.sync="search"
    cache-items
    :multiple="multiple"
    hide-no-data
    hide-selected
    hide-details
    :rules="rules"
    label="Select project(s)"
    clearable
    class="mt-0 pt-0"
    @update:search-input="pageReset"
  >
    <template v-slot:append-item="">
      <div v-intersect="endIntersect">
      </div>
    </template>
  </v-autocomplete>
</template>

<script>
import DiscordApi from '@core/api/discord'
import { mapState } from 'vuex'
import uniq from 'lodash/uniq'
import { DISCORD_MODULE_NAME } from '@/store/modules/discordStoreModule'

export default {
  props: {
    disabled: Boolean,
    multiple: {
      type: Boolean,
      default: false,
    },
    value: {
      type: [Number, Array],
      default: null,
    },
    isFilter: {
      type: Boolean,
      default: false,
    },
    rules: {
      type: Array,
      default: () => [],
    },
    accountProjects: {
      type: [Array],
      default: null,
    },
  },
  data() {
    return {
      loading: false,
      items: null,
      search: null,
      page: 1,
      total: 0,
    }
  },
  computed: {
    ...mapState({
      stateProxies(state) {
        return state[DISCORD_MODULE_NAME]?.project?.projects?.map(({ name, id }) => ({ text: name, value: id }))
      },
    }),
    localItems() {
      if (this.isFilter) {
        return [{ text: 'Empty', value: 'null' }].concat(this.items || this.stateProxies || [])
      }
      if (this.accountProjects && this.items) {
        return uniq(this.items.concat(this.accountProjects))
      }

      return this.items || this.stateProxies || []
    },
    localValue: {
      get() {
        return this.value
      },
      set(value) {
        this.$emit('input', value)
      },
    },
  },
  watch: {
    search(val) {
      this.tryFetchProxies(val)
    },
  },
  mounted() {
    if (!this.stateProxies?.length) {
      this.tryFetchProxies()
    }
  },
  methods: {
    async tryFetchProxies(search) {
      this.loading = true
      await DiscordApi.Project.fetchProjects({ search, limit: 20, page: this.page }).then(({ data }) => {
        this.items = data.items.map(({ name, id }) => ({ text: name, value: id }))
        this.total = data.total
      })
      this.loading = false
    },
    async  endIntersect(entries, observer, isIntersecting) {
      if (isIntersecting && this.page * 20 < this.total) {
        this.page += 1
        const { data } = await DiscordApi.Project.fetchProjects({ limit: 20, page: this.page })
        this.items.push(...data.items.map(({ name, id }) => ({ text: name, value: id })))
      }
    },
    pageReset() {
      this.page = 1
    },
  },
}
</script>

<style>

</style>
