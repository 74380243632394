<template>
  <v-dialog
    v-model="isOpen"
    width="90vw"
    persistent
    @click:outside="tryClose"
  >
    <v-card>
      <v-card-title>
        Change Password
        <v-spacer />
        <v-btn
          icon
          @click="tryClose"
        >
          <v-icon>{{ icons.mdiClose }}</v-icon>
        </v-btn>
      </v-card-title>

      <v-card-text>
        <v-form ref="form">
          <v-tabs
            v-model="formMode"
            grow
          >
            <v-tab
              :disabled="loading"
              @change="resetForm"
            >
              List
            </v-tab>
            <v-tab
              :disabled="loading"
              @change="resetForm"
            >
              Manual
            </v-tab>
          </v-tabs>
          <v-tabs-items
            v-model="formMode"
            class="my-4"
          >
            <v-tab-item>
              <v-row>
                <v-col>
                  <label>
                    Password List
                  </label>

                  <SelectPassword
                    v-model="
                      formData.password_list_id"
                    :rules="[formMode==0?simpleRequired:true]"
                  />
                </v-col>
              </v-row>
            </v-tab-item>
            <v-tab-item>
              <v-row>
                <v-col>
                  <label for="batch_delay">
                    Passwords
                  </label>

                  <v-textarea
                    v-model="formData.passwords"
                    :rules="[formMode==1?passwordValidator:true]"
                  />
                </v-col>
              </v-row>
            </v-tab-item>
          </v-tabs-items>
          <ActionsDelay
            v-model="formData"
          ></ActionsDelay>
        </v-form>
        <v-alert
          border="bottom"
          colored-border
          type="warning"
          elevation="2"
        >
          Feature available only for accounts with Password
        </v-alert>
      </v-card-text>

      <v-divider />

      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn
          color="primary"
          :loading="loading"
          @click="submit"
        >
          Update
        </v-btn>
        <v-btn
          :disabled="loading"
          @click="tryClose"
        >
          Cancel
        </v-btn>
      </v-card-actions>
    </v-card>

    <!--
      CONFIRM CANCEL UPDATE
    -->
    <ConfirmDialog
      ref="updateConfirm"
      :width="400"
      :z-index="99"
    >
      <template v-slot:default="{agree, cancel}">
        <v-card>
          <v-card-title>Confirm update cancel</v-card-title>
          <v-card-text>
            You have unsaved changes, you wanna leave
          </v-card-text>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn
              outlined
              @click="agree"
            >
              Agree
            </v-btn>
            <v-btn
              color="primary"
              @click="cancel"
            >
              Cancel
            </v-btn>
          </v-card-actions>
        </v-card>
      </template>
    </ConfirmDialog>
  </v-dialog>
</template>

<script>
import { mdiClose } from '@mdi/js'
import _cloneDeep from 'lodash/cloneDeep'
import isEqual from 'lodash/isEqual'
import pickBy from 'lodash/pickBy'
import {
  isNotEmptyString, passwordValidator, maximumValue, maxMultiplyValue, simpleRequired, isMore, integerValidator,
} from '@core/utils/validation'
import DiscordApi from '@core/api/discord'
import ActionsDelay from '@/components/ActionsDelay.vue'
import ConfirmDialog from '../../../ConfirmDialog.vue'
import SelectPassword from '../../profile-data/password/SelectPassword.vue'

const DEFAULT_DATA = {
  passwords: '',
  account_delay: 15,
  batch_delay: 1,
  batch_size: 5,
  user_id: null,
  password_list_id: null,
}

export default {
  components: { ConfirmDialog, SelectPassword, ActionsDelay },
  props: {
    selectedOptions: {
      type: Object,
      default: () => ({}),
    },
  },
  data: () => ({
    icons: {
      mdiClose,
    },
    loading: false,
    isOpen: false,
    accounts: [],
    initFormData: DEFAULT_DATA,
    formData: DEFAULT_DATA,
    formMode: 0,
  }),
  computed: {
    isChanged() {
      return !isEqual(this.initFormData, this.formData)
    },
  },
  methods: {
    maximumValue,
    maxMultiplyValue,
    simpleRequired,
    isMore,
    integerValidator,
    passwordValidator,
    isNotEmptyString,
    open(accounts) {
      this.isOpen = true
      this.accounts = accounts.map(el => el.id)
      this.formData = _cloneDeep(this.initFormData)
    },
    async submit() {
      if (this.$refs.form.validate()) {
        try {
          this.loading = true

          await DiscordApi.Account.changePassword({
            ...this.selectedOptions,
            ...pickBy(this.formData, value => !!value),
            passwords: this.formData.passwords && this.formData.passwords.split('\n').filter(el => el),
            account_delay: +this.formData.account_delay,
            batch_delay: +this.formData.batch_delay,
            batch_size: +this.formData.batch_size,
          })
          this.reset()
          this.$message.success('Password changed successfully')
        } catch (error) {
          this.$message.error(error)
        } finally {
          this.loading = false
          this.reset()
        }
      }
    },
    reset() {
      this.isOpen = false
      this.formData = _cloneDeep(this.initFormData)
    },
    async tryClose() {
      if (this.isChanged && await this.$refs.updateConfirm.open()) {
        this.reset()
      } else this.reset()
    },
    resetForm() {
      const delay = {
        account_delay: this.formData.account_delay,
        batch_delay: this.formData.batch_delay,
        batch_size: this.formData.batch_size,
      }
      this.formData = { ..._cloneDeep(this.initFormData), ...delay }
    },
  },
}
</script>

<style scoped>
label span{
  font-size: 0.7rem;
}
</style>
