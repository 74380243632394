<template>
  <v-dialog
    v-model="isOpen"
    width="90vw"
    persistent
    @click:outside="tryClose"
  >
    <v-card>
      <v-card-title>
        Change Discriminator
        <v-spacer />
        <v-btn
          icon
          @click="tryClose"
        >
          <v-icon>{{ icons.mdiClose }}</v-icon>
        </v-btn>
      </v-card-title>

      <v-card-text>
        <v-form ref="form">
          <v-tabs
            v-model="formMode"
            grow
          >
            <v-tab
              :disabled="loading"
              @change="resetForm"
            >
              Manual
            </v-tab>
            <v-tab
              :disabled="loading"
              @change="resetForm"
            >
              Account Id
            </v-tab>
          </v-tabs>
          <v-tabs-items
            v-model="formMode"
            class="my-4"
          >
            <v-tab-item>
              <v-row>
                <v-col>
                  <label for="discriminator">
                    Account Discriminator
                  </label>

                  <v-textarea
                    id="discriminator"
                    v-model.number="formData.discriminator"
                    type="number"
                    :rules="[formMode==0?isDiscriminator:true]"
                  ></v-textarea>
                </v-col>
              </v-row>
            </v-tab-item>
            <v-tab-item>
              <v-row>
                <v-col>
                  <label for="user_id">
                    Account Id
                  </label>

                  <v-text-field
                    id="user_id"
                    v-model.number="formData.user_id"
                    type="number"
                  />
                </v-col>
              </v-row>
            </v-tab-item>
          </v-tabs-items>
          <ActionsDelay
            v-model="formData"
          ></ActionsDelay>
        </v-form>
        <v-alert
          border="bottom"
          colored-border
          type="warning"
          elevation="2"
        >
          Feature available only for accounts with Nitro and Password
        </v-alert>
      </v-card-text>

      <v-divider />

      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn
          color="primary"
          :loading="loading"
          @click="submit"
        >
          Update
        </v-btn>
        <v-btn
          :disabled="loading"
          @click="tryClose"
        >
          Cancel
        </v-btn>
      </v-card-actions>
    </v-card>

    <!--
      CONFIRM CANCEL UPDATE
    -->
    <ConfirmDialog
      ref="updateConfirm"
      :width="400"
      :z-index="99"
    >
      <template v-slot:default="{agree, cancel}">
        <v-card>
          <v-card-title>Confirm update cancel</v-card-title>
          <v-card-text>
            You have unsaved changes, you wanna leave
          </v-card-text>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn
              outlined
              @click="agree"
            >
              Agree
            </v-btn>
            <v-btn
              color="primary"
              @click="cancel"
            >
              Cancel
            </v-btn>
          </v-card-actions>
        </v-card>
      </template>
    </ConfirmDialog>
  </v-dialog>
</template>

<script>
import { mdiClose } from '@mdi/js'
import _cloneDeep from 'lodash/cloneDeep'
import isEqual from 'lodash/isEqual'
import pickBy from 'lodash/pickBy'
import {
  isNotEmptyString, maximumValue, maxMultiplyValue, simpleRequired, isMore, integerValidator,
} from '@core/utils/validation'
import DiscordApi from '@core/api/discord'
import ActionsDelay from '@/components/ActionsDelay.vue'
import ConfirmDialog from '../../../ConfirmDialog.vue'

const DEFAULT_DATA = {
  account_delay: 15,
  batch_delay: 1,
  batch_size: 5,
  discriminator: '',
  user_id: null,
}

export default {
  components: { ConfirmDialog, ActionsDelay },
  props: {
    selectedOptions: {
      type: Object,
      default: () => ({}),
    },
  },
  data: () => ({
    icons: {
      mdiClose,
    },
    loading: false,
    isOpen: false,
    accounts: [],
    initFormData: DEFAULT_DATA,
    formData: DEFAULT_DATA,
    formMode: 0,
  }),
  computed: {
    isChanged() {
      return !isEqual(this.initFormData, this.formData)
    },
  },
  methods: {
    isNotEmptyString,
    maximumValue,
    maxMultiplyValue,
    simpleRequired,
    isMore,
    integerValidator,
    open(accounts) {
      this.isOpen = true
      this.accounts = accounts.map(el => el.id)
      this.formData = _cloneDeep(this.initFormData)
    },
    async submit() {
      if (this.$refs.form.validate()) {
        try {
          this.loading = true
          await DiscordApi.Account.changeDiscriminator({
            ...this.selectedOptions,
            ...pickBy(this.formData, value => !!value),
            discriminator: this.formData.discriminator && this.formData.discriminator.split('\n').filter(el => el),
            account_delay: +this.formData.account_delay,
            batch_delay: +this.formData.batch_delay,
            batch_size: +this.formData.batch_size,
          })
          this.reset()
          this.$message.success('Discriminator changed successfully')
        } catch (error) {
          this.$message.error(error)
        } finally {
          this.loading = false
          this.reset()
        }
      }
    },
    isDiscriminator(discriminator = '') {
      const regExp = /^([0-9]{4})$/
      let discriminators = []
      if (typeof discriminator === 'string') {
        discriminators = discriminator.trim().split('\n')
      }
      let validDiscriminator = true
      discriminators.forEach(item => {
        if (validDiscriminator) {
          validDiscriminator = regExp.test(item)
        }
      })

      return (
      // eslint-disable-next-line operator-linebreak
        validDiscriminator ||
    'Discriminator must be 4 digits'
      )
    },
    reset() {
      this.isOpen = false
      this.formData = _cloneDeep(this.initFormData)
    },
    async tryClose() {
      if (this.isChanged && await this.$refs.updateConfirm.open()) {
        this.reset()
      } else this.reset()
    },
    resetForm() {
      const delay = {
        account_delay: this.formData.account_delay,
        batch_delay: this.formData.batch_delay,
        batch_size: this.formData.batch_size,
      }
      this.formData = { ..._cloneDeep(this.initFormData), ...delay }
    },
  },
}
</script>

<style scoped>
label span{
  font-size: 0.7rem;
}
</style>
