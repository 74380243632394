<template>
  <div>
    <div>
      <v-btn
        class="mr-4 mb-5"
        @click="() =>{
          if(selectedAccounts.length>0){
            onActionSelected('exportAccounts')}
          else{
            $message.info('No accounts selected')
          }
        }"
      >
        <v-icon left>
          {{ icons.mdiDownload }}
        </v-icon>
        Export accounts
      </v-btn>
      <v-btn
        class="mb-5 mr-4"
        @click="() =>{
          if(selectedAccounts.length>0){
            onActionSelected('updateInfo')}
          else{
            $message.info('No accounts selected')
          }
        }"
      >
        <v-icon left>
          {{ icons.mdiUpdate }}
        </v-icon>
        Update Info
      </v-btn>
      <v-btn
        class="mb-5"
        @click="() =>{
          if(selectedAccounts.length>0){
            onActionSelected('fastJoin')}
          else{
            $message.info('No accounts selected')
          }
        }"
      >
        <v-icon left>
          {{ icons.mdiServerPlus }}
        </v-icon>
        Fast join
      </v-btn>
    </div>
    <v-card>
      <v-card-title class="d-flex justify-space-between">
        <div class="actions">
          <MultiActions
            :is-disabled="selectedAccounts.length===0"
            @select="onActionSelected"
          />
        </div>
        <TableSearch
          v-model="tableOptions.search"

          :show-filters.sync="showFilters"

          :check-filter-toggle="checkFilterToggle"
        />
        <AddNewAccount
          @submitted="tryFetchAccounts"
        />
      </v-card-title>
      <v-card-text>
        <v-data-table
          v-model="selectedAccounts"
          hide-default-footer
          :mobile-breakpoint="0"
          show-select
          :server-items-length="total"
          item-key="id"
          loading-text="Loading accounts ..."
          show-expand
          :loading="loading"
          :items="accounts"
          :headers="accountHeaders"
          :options.sync="tableOptions"
          :footer-props="{
            'items-per-page-options': pages,
            'show-first-last-page': true,
            'show-current-page': true,
          }"
        >
          <template
            v-slot:top
          >
            <v-layout
              v-show="showFilters"
              class="pa-4 pt-0"
            >
              <v-row>
                <v-col cols="3">
                  <v-range-slider
                    v-model="projectRange"
                    thumb-label
                    :height="42"
                    class="mt-4"
                    :step="1"
                    :min="0"
                    :max="totalProjects"
                    dense
                    hide-details
                    label="Project Count"
                  ></v-range-slider>
                  <SelectProject
                    v-model="tableOptions.project"
                    label="Projects"
                    placeholder="Select projects(s)"
                    multiple
                    hide-details
                    :is-filter="true"
                  ></SelectProject>
                  <SelectTag
                    v-model="tableOptions.tags"
                    multiple
                  />
                </v-col>
                <v-col cols="3">
                  <v-select
                    v-model="tableOptions.status"
                    label="Status"
                    placeholder="Select status"
                    hide-details
                    multiple
                    chips
                    :items="statuses"
                    clearable
                  >
                  </v-select>
                  <v-select
                    v-model="tableOptions.verification"
                    label="Verification"
                    placeholder="Select verification"
                    multiple
                    chips
                    hide-details
                    :items="verifications"
                    clearable
                  ></v-select>
                </v-col>
                <v-col cols="3">
                  <v-select
                    v-model="tableOptions.type"
                    label="Types"
                    placeholder="Select type(s)"
                    multiple
                    chips
                    hide-details
                    :ripple="false"
                    :items="types"
                    clearable
                  >
                    <template v-slot:item="data">
                      <v-simple-checkbox
                        :value="data.attrs['aria-selected'] === 'true'"
                      />
                      <v-list-item-icon class="mr-2">
                        <v-icon v-text="typeIcons[data.item.value]"></v-icon>
                      </v-list-item-icon>
                      <v-list-item-content>
                        <v-list-item-title>{{ data.item.text }}</v-list-item-title>
                      </v-list-item-content>
                    </template>
                  </v-select>
                  <SelectProxy
                    v-model="tableOptions.proxy"
                    label="Proxy"
                    placeholder="Select proxy"
                    hide-details
                    multiple
                    :is-filter="true"
                  ></SelectProxy>
                </v-col>
                <v-col cols="3">
                  <v-checkbox
                    v-model="tableOptions.nitro"
                    hide-details
                    label="Nitro"
                  />
                  <v-checkbox
                    v-model="tableOptions.spam"
                    hide-details
                    label="Spam"
                  />
                </v-col>
              </v-row>
            </v-layout>
          </template>
          <!-- SELECT ROW-->
          <template v-slot:header.data-table-select="{ props, on }">
            <CustomSelectRows
              :value="props"
              :page="+tableOptions.itemsPerPage"
              :total="total"
              :is-all.sync="isAllSelected"
              v-on="on"
            />
          </template>
          <template v-slot:item.numbering="{ index }">
            {{ getNumbering(index) }}.
          </template>
          <template v-slot:expanded-item="{ headers, item }">
            <td colspan="2"></td>
            <td
              class="pa-4"
              :colspan="headers.length-2"
            >
              <AccountExpand :account="item" />
            </td>
          </template>
          <template v-slot:item.status="{ item, value }">
            <div class="d-flex align-center">
              <v-tooltip
                right
              >
                <template v-slot:activator="{ on, attrs }">
                  <v-icon
                    v-if="value === 'active'"
                    class="mr-4"
                    color="success"
                    v-bind="attrs"
                    v-on="on"
                  >
                    {{ icons.mdiCheckCircleOutline }}
                  </v-icon>
                  <v-icon
                    v-else-if="value === 'locked'"
                    v-bind="attrs"
                    class="mr-4"
                    color="warning"
                    v-on="on"
                  >
                    {{ icons.mdiLockOutline }}
                  </v-icon>
                  <v-icon
                    v-else-if="value === 'banned'"
                    v-bind="attrs"
                    class="mr-4"
                    color="error"
                    v-on="on"
                  >
                    {{ icons.mdiCancel }}
                  </v-icon>
                </template>
                <span>{{ value }}</span>
              </v-tooltip>
            </div>
          </template>
          <template v-slot:item.username="{ item, value }">
            <span dir="ltr">{{ value }}</span>
            <span v-show="item.discriminator">#{{ item.discriminator }}</span>
          </template>
          <template v-slot:item.verification="{ item }">
            <div class="d-flex align-center">
              <div class="d-flex flex-column align-start pa-2">
                <v-chip
                  v-if="item.spammer"
                  label
                  small
                  text-color="white"
                  color="warning"
                  class="mb-2"
                >
                  Spammer
                </v-chip>
                <v-chip
                  v-if="!item.proxy"
                  label
                  small
                  text-color="white"
                  color="error"
                  class="mb-2"
                >
                  No proxy
                </v-chip>
                <v-chip
                  v-if=" item.limited_access"
                  label
                  small
                  text-color="white"
                  color="teal"
                  class="mb-2"
                >
                  Limited access
                </v-chip>
                <v-chip
                  v-if="item.nitro"
                  label
                  class="mb-2"
                  small
                  text-color="white"
                  color="info"
                >
                  Nitro
                </v-chip>
                <v-tooltip
                  v-if="item.verification"
                  right
                >
                  <template
                    v-slot:activator="{ on, attrs }"
                  >
                    <v-chip
                      label
                      small
                      text-color="white"
                      color="primary"
                      v-bind="attrs"
                      v-on="on"
                    >
                      Verification:
                      <v-icon
                        small
                        right
                      >
                        {{ verificationIcons[item.verification] }}
                      </v-icon>
                    </v-chip>
                  </template>
                  <span>{{ item.verification }}</span>
                </v-tooltip>
              </div>
            </div>
          </template>
          <template v-slot:item.type="{ value }">
            <v-tooltip
              v-for="item in value"
              :key="item"
              bottom
            >
              <template v-slot:activator="{ on, attrs }">
                <v-icon
                  v-bind="attrs"
                  v-on="on"
                >
                  {{ typeIcons[item] || item }}
                </v-icon>
              </template>
              <span>{{ item }}</span>
            </v-tooltip>
          </template>
          <template v-slot:item.projects="{item, value }">
            <template v-if="value&&value.length > 5">
              <v-chip
                v-for="el in value.slice(0,item.projectsCount)"
                :key="el.id"
                class="ma-2"
                role="button"
                :to="{name: 'discordProjectDetails', params: {id: el.id}}"
              >
                {{ el.name }}
                <v-icon
                  small
                  class="ml-1"
                  color="primary"
                >
                  {{ icons.mdiOpenInNew }}
                </v-icon>
              </v-chip>
              <v-chip
                color="primary"
                class="ma-2"
                @click="()=>{
                  item.isShowMoreProjects=!item.isShowMoreProjects
                  item.projectsCount=item.isShowMoreProjects?value.length:3
                }"
              >
                {{ item.isShowMoreProjects?'Hide':`${value.length-3} more` }}
              </v-chip>
            </template>
            <template v-else>
              <v-chip
                v-for="el in value"
                :key="el.id"
                class="ma-1"
                role="button"
                :to="{name: 'discordProjectDetails', params: {id: el.id}}"
              >
                {{ el.name }}
                <v-icon
                  class="ml-1"
                  small
                  color="primary"
                >
                  {{ icons.mdiOpenInNew }}
                </v-icon>
              </v-chip>
            </template>
          </template>
          <template v-slot:item.tags="{item, value }">
            <template v-if="value&&value.length > 5">
              <v-chip
                v-for="el in value.slice(0, item.tagsCount)"
                :key="el.id"
                class="ma-2"
                role="button"
              >
                {{ el }}
              </v-chip>
              <v-chip
                class="ma-2"
                color="primary"
                @click="()=>{
                  item.isShowMoreTags=!item.isShowMoreTags
                  item.tagsCount=item.isShowMoreTags?value.length:3
                }"
              >
                {{ item.isShowMoreTags?'Hide':`${value.length-3} more` }}
              </v-chip>
            </template>
            <template v-else>
              <v-chip
                v-for="el in value"
                :key="el.id"
                class="ma-2"
                role="button"
              >
                {{ el }}
              </v-chip>
            </template>
          </template>
          <template v-slot:item.action="{ item }">
            <v-layout d-flex>
              <v-tooltip
                close-delay="1500"
                top
                :disabled="isExtEnabled==='true'"
              >
                <template
                  v-slot:activator="{ on, attrs }"
                >
                  <v-btn
                    v-bind="attrs"
                    icon
                    class="mr-4"
                    :class="{'disabled--tooltip':!isExtEnabled||!item.token}"
                    v-on="on"
                    @click="() =>{ openDiscordAccount(item.token)}"
                  >
                    <v-icon>{{ icons.mdiDiscord }}</v-icon>
                  </v-btn>
                </template>
                <span>Install <a @click="()=>{$router.push({name:'home'})}">chrome Extension</a> to open this account in browser</span>
              </v-tooltip>
              <TableActions
                :loading="
                  item.loading"
                @edit="openAccountEditModal(item)"
                @copy="openCopyAccountModal(item.id)"
                @delete="tryDeleteAccount(item.id)"
              />
            </v-layout>
          </template>
          <template v-slot:footer="{ props, on }">
            <TableFooter
              v-bind="props"
              v-on="on"
            >
              <v-btn
                v-if="selectedAccounts.length"
                depressed
                :disabled="isAllSelected"
                :plain="isAllSelected"
                class="text-body-2 text-capitalize ml-2"
                :class="[isAllSelected?'white--text':'primary--text']"
                @click="()=>{
                  if(!isAllSelected){
                    onActionSelected('selectedAccounts')
                  }
                }"
              >
                <span v-if="isAllSelected">All selected</span>
                <span v-else>Selected: <span class="text-body-2 ml-1">{{ isAllSelected?total:selectedAccounts.length }}</span></span>
              </v-btn>
            </TableFooter>
          </template>
        </v-data-table>
      </v-card-text>

      <AccountEditModal
        ref="editAccountModal"
        @update="tryUpdateAccount"
      />

      <CopyAccount
        ref="copyAccountModal"
      />

      <UpdateInfo
        ref="updateInfo"
        :selected-options="selectedOptions"
      />
      <ChangeAvatar
        ref="changeAvatar"
        :selected-options="selectedOptions"
      />
      <ChangeBanner
        ref="changeBanner"
        :selected-options="selectedOptions"
      />
      <ChangeBio
        ref="changeBio"
        :selected-options="selectedOptions"
      />
      <ChangeDiscriminator
        ref="changeDiscriminator"
        :selected-options="selectedOptions"
      />
      <ChangeNickname
        ref="changeNickname"
        :selected-options="selectedOptions"
      />
      <ChangePassword
        ref="changePassword"
        :selected-options="selectedOptions"
      />
      <ChangeProfileColor
        ref="changeProfileColor"
        :selected-options="selectedOptions"
      />
      <ChangeProject
        ref="changeProject"
        :selected-options="selectedOptions"
        :load-accounts="tryFetchAccounts"
      />
      <ChangeTag
        ref="changeTag"
        :selected-options="selectedOptions"
        :load-accounts="tryFetchAccounts"
      />
      <ChangeProxy
        ref="changeProxy"
        :selected-options="selectedOptions"
        :load-accounts="tryFetchAccounts"
      />
      <ChangeType
        ref="changeType"
        :selected-options="selectedOptions"
        :load-accounts="tryFetchAccounts"
      />
      <ExportAccounts
        ref="exportAccounts"
        :selected-accounts="selectedAccounts"
        :is-all-selected="isAllSelected"
        :filters="mappedOptions"
        :projects-total="total"
      >
      </ExportAccounts>
      <LeaveGuild
        ref="leaveGuild"
        :selected-options="selectedOptions"
      >
      </LeaveGuild>

      <FastJoin
        ref="fastJoin"
        :selected-options="selectedOptions"
      />
      <SelectedAccounts
        ref="selectedAccounts"
        :selected-items="selectedAccounts"
        @unselect="(data)=>{deleteSelectedAccounts(data)}"
      >
      </SelectedAccounts>
      <ConfirmDialog
        ref="deleteAccountConfirm"
        max-width="400"
      >
        <template v-slot="{agree, cancel}">
          <v-card>
            <v-card-title>Delete account</v-card-title>
            <v-card-text>
              Do you really want to delete account
            </v-card-text>
            <v-card-actions>
              <v-spacer />
              <v-btn
                color="error"
                @click="agree"
              >
                <v-icon>{{ icons.mdiDeleteForever }}</v-icon>
                Delete
              </v-btn>
              <v-btn
                @click="cancel"
              >
                Cancel
              </v-btn>
            </v-card-actions>
          </v-card>
        </template>
      </ConfirmDialog>
    </v-card>
  </div>
</template>

<script>
import {
  mdiDeleteForever,
  mdiCheck,
  mdiCancel,
  mdiCheckCircleOutline,
  mdiLockOutline,
  mdiDotsVertical,
  mdiDotsHorizontal,
  mdiFilter,
  mdiPencil,
  mdiMagnify,
  mdiShieldOffOutline,
  mdiCellphoneKey,
  mdiEmailCheckOutline,
  mdiCloseCircleOutline,
  mdiCloseOctagonOutline,
  mdiClipboardTextOutline,
  mdiLinkVariantPlus,
  mdiGiftOpenOutline,
  mdiFormatListBulletedSquare,
  mdiDownload,
  mdiOpenInNew,
  mdiDiscord,
  mdiUpdate,
  mdiServerPlus,
} from '@mdi/js'
import { types, statuses, verifications } from '@core/constants'
import pick from 'lodash/pick'
import { mapActions, mapMutations, mapState } from 'vuex'
import _cloneDeep from 'lodash/cloneDeep'
import store from '@/store'
import DiscordApi from '@/@core/api/discord'
import discordStoreModule, { DISCORD_MODULE_NAME } from '@/store/modules/discordStoreModule'

import CustomSelectRows from '@/components/CustomSelectRows.vue'
import AddNewAccount from '@/components/discord/account/AddNew.vue'
import AccountEditModal from '@/components/discord/account/EditModal.vue'
import CopyAccount from '@/components/discord/account/modals/CopyAccount.vue'

import ConfirmDialog from '@/components/ConfirmDialog.vue'
import TableActions from '@/components/discord/account/TableActions.vue'
import MultiActions from '@/components/discord/account/MultiActions.vue'
import SelectedAccounts from '@/components/discord/account/SelectedAccounts.vue'
import UpdateInfo from '@/components/discord/account/modals/UpdateInfo.vue'
import ChangeAvatar from '@/components/discord/account/modals/ChangeAvatar.vue'
import ChangeBio from '@/components/discord/account/modals/ChangeBio.vue'
import ChangeDiscriminator from '@/components/discord/account/modals/ChangeDiscriminator.vue'
import ChangeNickname from '@/components/discord/account/modals/ChangeNickname.vue'
import ChangePassword from '@/components/discord/account/modals/ChangePassword.vue'
import ChangeProject from '@/components/discord/account/modals/ChangeProject.vue'
import ChangeProxy from '@/components/discord/account/modals/ChangeProxy.vue'
import ChangeType from '@/components/discord/account/modals/ChangeType.vue'
import SelectProxy from '@/components/proxy/SelectProxy.vue'
import SelectProject from '@/components/discord/project/SelectProject.vue'
import ChangeBanner from '@/components/discord/account/modals/ChangeBanner.vue'
import SelectTag from '@/components/discord/account/SelectTag.vue'
import TableFooter from '@/components/TableFooter.vue'
import { castToArray } from '@/@core/utils'
import ChangeProfileColor from '@/components/discord/account/modals/ChangeProfileColor.vue'
import ExportAccounts from '@/components/ExportAccounts.vue'
import ChangeTag from '@/components/discord/account/modals/ChangeTag.vue'
import TableSearch from '@/components/TableSearch.vue'
import AccountExpand from '@/components/discord/account/AccountExpand.vue'
import LeaveGuild from '@/components/discord/account/modals/LeaveGuild.vue'
import FastJoin from '@/components/discord/account/modals/FastJoin.vue'

export default {
  components: {
    CustomSelectRows,
    AddNewAccount,
    AccountEditModal,
    ConfirmDialog,
    TableActions,
    MultiActions,
    UpdateInfo,
    ChangeAvatar,
    ChangeBio,
    ChangeDiscriminator,
    ChangeNickname,
    ChangePassword,
    ChangeProject,
    ChangeProxy,
    ChangeType,
    SelectProxy,
    SelectProject,
    CopyAccount,
    ChangeBanner,
    SelectTag,
    TableFooter,
    ChangeProfileColor,
    ExportAccounts,
    SelectedAccounts,
    ChangeTag,
    TableSearch,
    AccountExpand,
    LeaveGuild,
    FastJoin,
  },
  data() {
    return {
      loading: true,
      isExtEnabled: sessionStorage.getItem('discordExt'),
      types,
      statuses,
      isAllSelected: false,
      verifications,
      tableOptions: {
        project_count_min: +(this.$route.query.project_count_min || 0),
        project_count_max: +(this.$route.query.project_count_max || 0),
        spam: this.$route.query.spam === 'true',
        nitro: this.$route.query.nitro === 'true',
        type: castToArray(this.$route.query.type || null),
        proxy: castToArray(+this.$route.query.proxy || null),
        status: castToArray(this.$route.query.status || null),
        verification: castToArray(this.$route.query.verification),
        project: castToArray(+this.$route.query.project || null),
        tags: castToArray(+this.$route.query.tags || null),
        search: this.$route.query.search || '',
        sortBy: this.$route.query.sortBy ? [this.$route.query.sortBy] : [],
        sortDesc: +this.$route.query.sortDesc ? [Boolean(+this.$route.query.sortDesc)] : [],
        page: +this.$route.query.page ? +this.$route.query.page : 1,
        itemsPerPage: +this.$route.query.limit ? +this.$route.query.limit : localStorage.getItem('itemsPerPage') || 50,
      },
      search: '',
      selectedAccounts: [],
      totalProjects: 0,
      projectRange: [
        +(this.$route.query.project_count_min || 0),
        +(this.$route.query.project_count_max || 0),
      ],
      total: 50,
      pages: [50, 100, 200],
      verificationIcons: {
        'phone verified': mdiCellphoneKey,
        'mail verified': mdiEmailCheckOutline,
        'non verified': mdiShieldOffOutline,
        null: mdiCloseOctagonOutline,
      },
      typeIcons: {
        referral: mdiLinkVariantPlus,
        giveaway: mdiGiftOpenOutline,
        'white list': mdiFormatListBulletedSquare,
      },
      showFilters: false,
      icons: {
        mdiDotsVertical,
        mdiDotsHorizontal,
        mdiFilter,
        mdiDeleteForever,
        mdiPencil,
        mdiMagnify,
        mdiLockOutline,
        mdiCheck,
        mdiCloseCircleOutline,
        mdiCheckCircleOutline,
        mdiClipboardTextOutline,
        mdiCancel,
        mdiDownload,
        mdiOpenInNew,
        mdiDiscord,
        mdiUpdate,
        mdiServerPlus,
      },
      accountHeaders: [
        {
          cellClass: 'pa-0 text-center',
          value: 'numbering',
          align: 'center',
          sortable: false,
        },
        {
          text: '',
          class: 'pa-0 pr-4',
          cellClass: 'pa-0 pr-4',
          value: 'data-table-expand',
        },
        {
          text: '',
          class: 'pa-0',
          cellClass: 'pa-0',
          value: 'data-table-select',
        },
        {
          text: 'Status',
          value: 'status',
          width: '90px',
          sortable: true,
          filterable: true,
        },
        {
          text: 'Username',
          value: 'username',
          sortable: true,
          filterable: true,
        },
        {
          text: 'Type',
          value: 'type',
          sortable: true,
          filterable: true,
          width: '104px',
        },
        {
          text: 'Projects',
          value: 'projects',
          sortable: false,
          filterable: true,
          width: '20%',
        },
        {
          text: 'Tags',
          value: 'tags',
          sortable: true,
          filterable: true,
          width: '20%',
        },
        {
          text: 'Status',
          value: 'verification',
          sortable: true,
          filterable: true,
        },
        {
          align: 'end',
          value: 'action',
          sortable: false,
        },
      ],
    }
  },

  computed: {
    ...mapState({
      accounts(state) {
        return state[DISCORD_MODULE_NAME]?.account.accounts.map(account => ({
          ...account,
          isShowMoreProjects: false,
          projectsCount: 3,
          isShowMoreTags: false,
          tagsCount: 3,
        }))
      },
    }),
    selectedOptions() {
      return this.isAllSelected
        ? { filter: this.mappedOptions }
        : { accounts: this.selectedAccounts.map(el => el.id) }
    },
    mappedOptions() {
      const opts = this.$route.query

      return opts
    },
  },

  watch: {
    total() {
      this.tableOptions.page = 1
    },
    projectRange: {
      handler([min, max]) {
        this.tableOptions.project_count_min = min || 0
        this.tableOptions.project_count_max = max || 0

        // project_count_max: max || 0
      },
      deep: true,
    },
    tableOptions: {
      handler(value) {
        const query = pick(value, [
          'page', 'search', 'type', 'proxy', 'project',
          'status', 'verification', 'spam', 'nitro',
          'project_count_min', 'project_count_max', 'tags',
        ])

        query.limit = value.itemsPerPage

        if (value.sortBy?.length) {
          [query.sortBy] = value.sortBy
        }

        if (value.sortDesc?.length) {
          [query.sortDesc] = value.sortDesc
        }

        Object.keys(query).forEach(key => {
          if (!query[key] && key !== 'sortDesc') {
            delete query[key]
          }
        })
        this.tryFetchAccounts(query)
        this.$router.replace({ query }).catch(() => {})
      },
      deep: true,
    },
  },
  async mounted() {
    // Register module
    if (!store.hasModule(DISCORD_MODULE_NAME)) {
      store.registerModule(DISCORD_MODULE_NAME, discordStoreModule)
    }
    this.getProjectsCount()
  },

  methods: {
    ...mapActions({
      fetchAccounts(dispatch, payload) {
        return dispatch(`${DISCORD_MODULE_NAME}/fetchAccounts`, payload)
      },
      updateAccount(dispatch, payload) {
        return dispatch(`${DISCORD_MODULE_NAME}/updateAccount`, payload)
      },
      removeAccount(dispatch, payload) {
        return dispatch(`${DISCORD_MODULE_NAME}/removeAccount`, payload)
      },
    }),

    ...mapMutations({
      commitSetAccounts(commit, payload) {
        return commit(`${DISCORD_MODULE_NAME}/SET_ACCOUNTS `, payload)
      },
      commitEditAccount(commit, payload) {
        return commit(`${DISCORD_MODULE_NAME}/EDIT_ACCOUNT`, payload)
      },
      commitDeleteAccount(commit, payload) {
        return commit(`${DISCORD_MODULE_NAME}/REMOVE_ACCOUNT`, payload)
      },
    }),

    onActionSelected(action) {
      if (action === 'delete') {
        this.tryDeleteManyAccounts()
      } else {
        this.$refs[action]?.open(this.selectedAccounts)
      }
    },

    openDiscordAccount(token) {
      if (this.isExtEnabled === 'true') {
        const event = new CustomEvent('discord-token', { detail: token })

        document.dispatchEvent(event)
      }
    },

    async tryFetchAccounts(params = this.mappedOptions) {
      this.loading = true
      try {
        const { data } = await this.fetchAccounts(params)
        this.total = data.total
      } catch (err) {
        this.$message.error(err)
      }
      this.loading = false
    },
    async tryDeleteManyAccounts() {
      this.$refs.deleteAccountConfirm.open().then(async () => {
        this.loading = true
        await DiscordApi.Account.removeMany(this.selectedOptions)
        this.selectedAccounts = this.selectedAccounts.filter(el => !this.selectedOptions.accounts.includes(el.id))
        this.loading = false
        this.tryFetchAccounts()
      })
    },
    async tryDeleteAccount(id) {
      this.$refs.deleteAccountConfirm.open().then(() => {
        this.commitEditAccount({ id, loading: true })
        this.removeAccount({ id }).then(() => {
          this.commitDeleteAccount({ id })
          this.selectedAccounts = this.selectedAccounts.filter(el => el.id !== id)
          this.$message.success('Account deleted successfully')
        }).catch(err => this.$message.error(err))
      })
    },

    async tryUpdateAccount(value) {
      this.commitEditAccount({ ...value, loading: true })
      const { data } = await this.updateAccount({ account: value })
      this.commitEditAccount(data)
    },

    openAccountEditModal(account) {
      this.$refs.editAccountModal.open(account)
    },

    openCopyAccountModal(account) {
      this.$refs.copyAccountModal.open(account)
    },
    checkFilterToggle() {
      const filters = _cloneDeep(this.tableOptions)
      const tableKeys = ['search', 'sortBy', 'sortDesc', 'page', 'itemsPerPage', 'groupBy', 'groupDesc', 'multiSort', 'mustSort']
      let filtersStatus = false
      Object.entries(filters).forEach(([key, value]) => {
        if (tableKeys.includes(key)) {
          delete filters[key]
        } else if ((!!value !== false && !Array.isArray(value)) || (value?.length && value?.length > 0)) {
          filtersStatus = true
        }
      })

      return filtersStatus
    },
    async getProjectsCount() {
      const { data } = await DiscordApi.Project.fetchProjects()
      this.totalProjects = data.total
    },
    deleteSelectedAccounts(data) {
      this.selectedAccounts = this.selectedAccounts.filter(el => el.id !== data)
    },
    getNumbering(index) {
      return (this.tableOptions.page - 1) * this.tableOptions.itemsPerPage + (index + 1)
    },
  },
}
</script>

<style scoped lang="scss">
.actions{
  width: 56px;
}
.v-tooltip__content {
  pointer-events: initial;
}
.disabled--tooltip{
  color: rgba(231, 227, 252, 0.3) !important;
}
</style>
