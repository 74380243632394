<template>
  <div>
    <v-row>
      <v-col md="2">
        Discord Account Id:
      </v-col>
      <v-col> {{ account.discord_user_id }}</v-col>
    </v-row>
    <v-row>
      <v-col md="2">
        Proxy:
      </v-col>
      <v-col>
        {{ (account.proxy && account.proxy.value) || 'no proxy' }}
        <CopyTextBtn
          v-if="!!account.proxy"
          :text="account.proxy.value"
        />
      </v-col>
    </v-row>
    <v-row>
      <v-col md="2">
        Proxy name:
      </v-col>
      <v-col> {{ (account.proxy && account.proxy.name) || 'no proxy' }}</v-col>
    </v-row>
    <v-row>
      <v-col md="2">
        Login:
      </v-col>
      <v-col> {{ account.login }}</v-col>
    </v-row>
    <v-row>
      <v-col md="2">
        Password:
      </v-col>
      <v-col>
        {{ account.password }}
        <CopyTextBtn
          v-if="!!account.password"
          :text="account.password"
        />
      </v-col>
    </v-row>
    <v-row class="mt-2">
      <v-col md="2">
        Token:
      </v-col>
      <v-col>
        {{ account.token }}
        <CopyTextBtn
          v-if="!!account.token"
          :text="account.token"
        />
      </v-col>
    </v-row>
    <v-row class="mt-2">
      <v-col md="2">
        Seller:
      </v-col>
      <v-col> {{ account.seller || 'no seller' }}</v-col>
    </v-row>
  </div>
</template>

<script>
import CopyTextBtn from '@/components/CopyTextBtn.vue'

export default {
  components: {
    CopyTextBtn,
  },
  props: {
    account: {
      type: Object,
      default: () => ({}),
    },
  },
}
</script>
