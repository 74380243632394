<template>
  <v-autocomplete
    v-model="localValue"
    chips
    deletable-chips
    :loading="loading"
    :disabled="disabled"
    :items="localItems"
    :search-input.sync="search"
    cache-items
    :multiple="multiple"
    hide-no-data
    hide-details
    label="Select bio"
    :rules="rules"
  ></v-autocomplete>
</template>

<script>
import DiscordApi from '@core/api/discord'
import { mapState } from 'vuex'
import { DISCORD_MODULE_NAME } from '@/store/modules/discordStoreModule'

export default {
  props: {
    disabled: Boolean,
    multiple: {
      type: Boolean,
      default: false,
    },
    value: {
      type: [Number, Array],
      default: null,
    },
    rules: {
      type: Array,
      default: null,
    },
  },
  data() {
    return {
      loading: false,
      items: null,
      search: null,
    }
  },
  computed: {
    ...mapState({
      stateProxies(state) {
        return state[DISCORD_MODULE_NAME]?.bio?.bios?.map(({ name, id }) => ({ text: name, value: id }))
      },
    }),
    localItems() {
      return this.items || this.stateProxies
    },
    localValue: {
      get() {
        return this.value
      },
      set(value) {
        this.$emit('input', value)
      },
    },
  },
  watch: {
    search(val) {
      this.tryFetchProxies(val)
    },
  },
  mounted() {
    if (!this.stateProxies?.length) {
      this.tryFetchProxies()
    }
  },
  methods: {
    async tryFetchProxies(search) {
      this.loading = true
      await DiscordApi.ProfileData.Bio.fetchBios({ q: search }).then(({ data }) => {
        this.items = data.items.map(({ name, id }) => ({ text: name, value: id }))
      })
      this.loading = false
    },
  },
}
</script>

<style>

</style>
