<template>
  <v-dialog
    v-model="isOpen"
    width="90vw"
    persistent
    @click:outside="tryClose"
  >
    <v-card>
      <v-card-title>
        Copy Account
        <v-spacer />
        <v-btn
          icon
          @click="tryClose"
        >
          <v-icon>{{ icons.mdiClose }}</v-icon>
        </v-btn>
      </v-card-title>

      <v-card-text>
        <v-form ref="form">
          <v-row>
            <v-col>
              <label for="user_id">
                Account Id
              </label>

              <v-text-field
                id="user_id"
                v-model.number="formData.user_id"
                type="number"
              />
            </v-col>
          </v-row>
          <v-row>
            <v-col>
              <label>
                Fields
              </label>

              <v-checkbox
                v-for="field in filedOptions"
                :key="field.value"
                v-model="formData.profile_fields_to_change"
                :value="field.value"
                :label="field.label"
              />
              <v-checkbox
                v-model="formData.profile_fields_to_change"
                value="invisible_symbol"
                label="Add Invisible Symbol to Username"
                :disabled="formData.profile_fields_to_change.includes('discriminator')
                  && formData.profile_fields_to_change.includes('username')?setInvisible():false"
              />
            </v-col>
          </v-row>
        </v-form>
      </v-card-text>

      <v-divider />

      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn
          color="primary"
          :loading="loading"
          @click="submit"
        >
          Copy
        </v-btn>
        <v-btn
          :disabled="loading"
          @click="tryClose"
        >
          Cancel
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import { mdiClose } from '@mdi/js'
import _cloneDeep from 'lodash/cloneDeep'

import DiscordApi from '@core/api/discord'

const DEFAULT_DATA = {
  user_id: '',
  profile_fields_to_change: [],
}

export default {
  props: {
    selectedOptions: {
      type: Object,
      default: () => ({}),
    },
  },
  data: () => ({
    icons: {
      mdiClose,
    },
    loading: false,
    isOpen: false,
    accounts: [],
    filedOptions: [
      {
        label: 'Username',
        value: 'username',
      }, {
        label: 'Bio',
        value: 'bio',
      }, {
        label: 'Discriminator',
        value: 'discriminator',
      }, {
        label: 'Avatar',
        value: 'avatar',
      }, {
        label: 'Profile Color',
        value: 'profile_color',
      }, {
        label: 'Banner',
        value: 'banner',
      },
    ],
    initFormData: DEFAULT_DATA,
    formData: DEFAULT_DATA,
  }),
  methods: {
    open(accountId) {
      this.isOpen = true
      this.accountId = accountId
      this.formData = _cloneDeep(this.initFormData)
    },
    async submit() {
      if (this.$refs.form.validate()) {
        try {
          this.loading = true
          await DiscordApi.Account.copyAccount({
            id: this.accountId,
            profile_fields_to_change: this.formData.profile_fields_to_change,
            user_id: this.formData.user_id,
          })
          this.reset()
          this.$message.success('Account copied successfully')
        } catch (error) {
          this.$message.error(error)
        } finally {
          this.loading = false
          this.reset()
        }
      }
    },
    reset() {
      this.isOpen = false
      this.formData = _cloneDeep(this.initFormData)
    },
    async tryClose() {
      this.reset()
    },
    setInvisible() {
      if (!this.formData.profile_fields_to_change.includes('invisible_symbol')) {
        this.formData.profile_fields_to_change.push('invisible_symbol')
      }

      return true
    },
  },
}
</script>

<style>

</style>
