<template>
  <v-dialog
    v-model="isOpen"
    width="90vw"
    persistent
    @click:outside="tryClose"
  >
    <v-card>
      <v-card-title>
        Change Type
        <v-spacer />
        <v-btn
          icon
          @click="tryClose"
        >
          <v-icon>{{ icons.mdiClose }}</v-icon>
        </v-btn>
      </v-card-title>

      <v-card-text>
        <v-form ref="form">
          <v-row>
            <v-col>
              <label>
                Add
              </label>

              <v-select
                v-model="formData.addType"
                :items="types"
                multiple
                :rules="[() => !!formData.deleteType.length || !!formData.addType.length ]"
              />
            </v-col>
          </v-row>
          <v-row>
            <v-col>
              <label>
                Delete
              </label>
              <v-select
                v-model="formData.deleteType"
                :items="types"
                multiple
                :rules="[() => !!formData.deleteType.length || !!formData.addType.length ]"
              />
            </v-col>
          </v-row>
        </v-form>
      </v-card-text>

      <v-divider />

      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn
          color="primary"
          :loading="loading"
          @click="submit"
        >
          Update
        </v-btn>
        <v-btn
          :disabled="loading"
          @click="tryClose"
        >
          Cancel
        </v-btn>
      </v-card-actions>
    </v-card>

    <!--
      CONFIRM CANCEL UPDATE
    -->
    <ConfirmDialog
      ref="updateConfirm"
      :width="400"
      :z-index="99"
    >
      <template v-slot:default="{agree, cancel}">
        <v-card>
          <v-card-title>Confirm update cancel</v-card-title>
          <v-card-text>
            You have unsaved changes, you wanna leave
          </v-card-text>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn
              outlined
              @click="agree"
            >
              Agree
            </v-btn>
            <v-btn
              color="primary"
              @click="cancel"
            >
              Cancel
            </v-btn>
          </v-card-actions>
        </v-card>
      </template>
    </ConfirmDialog>
  </v-dialog>
</template>

<script>
import { mdiClose } from '@mdi/js'
import _cloneDeep from 'lodash/cloneDeep'
import isEqual from 'lodash/isEqual'

import DiscordApi from '@core/api/discord'

import ConfirmDialog from '../../../ConfirmDialog.vue'

const DEFAULT_DATA = {
  addType: [],
  deleteType: [],
}

export default {
  components: { ConfirmDialog },
  props: {
    selectedOptions: {
      type: Object,
      default: () => ({}),
    },
    loadAccounts: {
      type: Function,
      default: () => ({}),
    },
  },
  data: () => ({
    icons: {
      mdiClose,
    },
    loading: false,
    isOpen: false,
    accounts: [],
    types: [{
      text: 'White List',
      value: 'white list',
    }, {
      text: 'Giveaway',
      value: 'giveaway',
    },

    // {
    //   text: 'Referral',
    //   value: 'referral',
    // },
    ],
    initFormData: DEFAULT_DATA,
    formData: DEFAULT_DATA,
  }),
  computed: {
    isChanged() {
      return !isEqual(this.initFormData, this.formData)
    },
  },
  methods: {
    open(accounts) {
      this.isOpen = true
      this.accounts = accounts.map(el => el.id)
      this.formData = _cloneDeep(this.initFormData)
    },
    async submit() {
      if (this.$refs.form.validate()) {
        try {
          this.loading = true
          await DiscordApi.Account.changeType({ ...this.selectedOptions, ...this.formData })
          this.reset()
          this.$message.success('Type changed successfully')
          this.loadAccounts()
        } catch (error) {
          this.$message.error(error)
        } finally {
          this.loading = false
          this.reset()
        }
      }
    },
    reset() {
      this.isOpen = false
      this.formData = _cloneDeep(this.initFormData)
    },
    async tryClose() {
      if (this.isChanged && await this.$refs.updateConfirm.open()) {
        this.reset()
      } else this.reset()
    },
  },
}
</script>

<style>

</style>
