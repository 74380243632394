var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-dialog',{attrs:{"width":"90vw","persistent":"","scrollable":""},on:{"click:outside":_vm.tryClose},model:{value:(_vm.isOpen),callback:function ($$v) {_vm.isOpen=$$v},expression:"isOpen"}},[_c('v-card',[_c('v-card-title',[_vm._v(" Change Bio "),_c('v-spacer'),_c('v-btn',{attrs:{"icon":""},on:{"click":_vm.tryClose}},[_c('v-icon',[_vm._v(_vm._s(_vm.icons.mdiClose))])],1)],1),_c('v-card-text',[_c('v-form',{ref:"form"},[_c('v-tabs',{attrs:{"grow":""},model:{value:(_vm.formMode),callback:function ($$v) {_vm.formMode=$$v},expression:"formMode"}},[_c('v-tab',{attrs:{"disabled":_vm.loading},on:{"change":_vm.resetForm}},[_vm._v(" List ")]),_c('v-tab',{attrs:{"disabled":_vm.loading},on:{"change":_vm.resetForm}},[_vm._v(" Manual ")]),_c('v-tab',{attrs:{"disabled":_vm.loading},on:{"change":_vm.resetForm}},[_vm._v(" Account Id ")])],1),_c('v-tabs-items',{staticClass:"my-4",model:{value:(_vm.formMode),callback:function ($$v) {_vm.formMode=$$v},expression:"formMode"}},[_c('v-tab-item',[_c('v-row',[_c('v-col',[_c('label',[_vm._v(" Bio List ")]),_c('SelectBio',{attrs:{"rules":[_vm.formMode==0?_vm.simpleRequired:true]},model:{value:(
                    _vm.formData.bio_list_id),callback:function ($$v) {_vm.$set(_vm.formData, "bio_list_id", $$v)},expression:"\n                    formData.bio_list_id"}})],1)],1)],1),_c('v-tab-item',[_c('v-row',[_c('v-col',[_c('label',{attrs:{"for":"batch_delay"}},[_vm._v(" Bios ")]),_c('v-textarea',{attrs:{"rules":[_vm.formMode==1?_vm.isNotEmptyString:true]},model:{value:(_vm.formData.bios),callback:function ($$v) {_vm.$set(_vm.formData, "bios", $$v)},expression:"formData.bios"}}),_c('v-alert',{staticClass:"mt-2",attrs:{"border":"bottom","colored-border":"","type":"warning","elevation":"2"}},[_vm._v(" Press Enter twice to split bio ")])],1)],1),_c('v-row',[_c('v-col',{staticClass:"scroll-bio",attrs:{"cols":"12"}},[_c('v-data-table',{attrs:{"hide-default-footer":"","mobile-breakpoint":0,"items":_vm.calculatedOptions,"headers":[{
                    text: 'Bios',
                    value: 'bios',
                  }],"disable-pagination":""}})],1)],1)],1),_c('v-tab-item',[_c('v-row',[_c('v-col',[_c('label',{attrs:{"for":"user_id"}},[_vm._v(" Account Id ")]),_c('v-text-field',{attrs:{"id":"user_id","type":"number"},model:{value:(_vm.formData.user_id),callback:function ($$v) {_vm.$set(_vm.formData, "user_id", _vm._n($$v))},expression:"formData.user_id"}})],1)],1)],1)],1),_c('ActionsDelay',{model:{value:(_vm.formData),callback:function ($$v) {_vm.formData=$$v},expression:"formData"}})],1)],1),_c('v-divider'),_c('v-card-actions',[_c('v-spacer'),_c('v-btn',{attrs:{"color":"primary","loading":_vm.loading},on:{"click":_vm.submit}},[_vm._v(" Update ")]),_c('v-btn',{attrs:{"disabled":_vm.loading},on:{"click":_vm.tryClose}},[_vm._v(" Cancel ")])],1)],1),_c('ConfirmDialog',{ref:"updateConfirm",attrs:{"width":400,"z-index":99},scopedSlots:_vm._u([{key:"default",fn:function(ref){
                  var agree = ref.agree;
                  var cancel = ref.cancel;
return [_c('v-card',[_c('v-card-title',[_vm._v("Confirm update cancel")]),_c('v-card-text',[_vm._v(" You have unsaved changes, you wanna leave ")]),_c('v-card-actions',[_c('v-spacer'),_c('v-btn',{attrs:{"outlined":""},on:{"click":agree}},[_vm._v(" Agree ")]),_c('v-btn',{attrs:{"color":"primary"},on:{"click":cancel}},[_vm._v(" Cancel ")])],1)],1)]}}])})],1)}
var staticRenderFns = []

export { render, staticRenderFns }