<template>
  <v-card>
    <v-card-title>Create Account</v-card-title>
    <v-card-text :class="{'scroll-add':isProjectDetails}">
      <v-stepper v-model="step">
        <v-stepper-header>
          <v-stepper-step
            :complete="step > 1"
            :step="1"
          >
            Account info
          </v-stepper-step>

          <v-divider></v-divider>

          <v-stepper-step
            :step="2"
          >
            Select proxy
          </v-stepper-step>
          <v-divider></v-divider>
          <v-stepper-step
            :step="3"
          >
            Get account info
          </v-stepper-step>
        </v-stepper-header>
        <v-stepper-items>
          <v-stepper-content
            class="pt-0"
            :step="1"
          >
            <v-form ref="form">
              <v-row>
                <v-col
                  cols="12"
                  lg="6"
                >
                  <v-col
                    cols="12"
                  >
                    <label for="stringValues">Credentials</label>
                  </v-col>
                  <v-col
                    cols="12"
                  >
                    <v-textarea
                      id="stringValues"
                      v-model="stringValues"
                      type="textarea"
                      outlined
                      dense
                      hide-details="auto"
                      placeholder="Format: [login:password:token] | [token]"
                      :disabled="loading"
                      :rules="[isNotEmptyString]"
                    ></v-textarea>
                  </v-col>
                </v-col>
                <v-col
                  lg="6"
                  cols="12"
                >
                  <v-data-table
                    hide-default-footer
                    :mobile-breakpoint="0"
                    :items="calculatedOptions"
                    :headers="headers"
                    disable-pagination
                  />
                </v-col>
              </v-row>
              <v-row>
                <v-col
                  cols="12"
                  md="6"
                >
                  <v-col>
                    <label for="seller">Seller</label>
                  </v-col>
                  <v-col>
                    <v-text-field
                      id="seller"
                      v-model="seller"
                      outlined
                      dense
                      hide-details="auto"
                      placeholder="Enter seller"
                      :disabled="loading"
                      :rules="[stringLengthValidation(null,100)]"
                    ></v-text-field>
                  </v-col>
                  <v-col>
                    <label for="type">Type</label>
                  </v-col>
                  <v-col>
                    <v-select
                      id="type"
                      v-model="type"
                      outlined
                      dense
                      multiple
                      hide-details="auto"
                      placeholder="Select type(s)"
                      :items="types"
                      :disabled="loading"
                    ></v-select>
                  </v-col>
                </v-col>
                <v-col
                  cols="12"
                  md="6"
                >
                  <v-col v-if="!isProjectDetails">
                    <label for="type">Project</label>
                  </v-col>
                  <v-col v-if="!isProjectDetails">
                    <SelectProject
                      v-model="project"
                      multiple
                    />
                  </v-col>
                  <v-col>
                    <label for="tags">Tags</label>
                  </v-col>
                  <v-col>
                    <SelectTag
                      v-model="tags"
                      multiple
                    />
                  </v-col>
                </v-col>
              </v-row>
            </v-form>
          </v-stepper-content>
          <v-stepper-content
            class="pt-0"
            :step="2"
          >
            <TableSearch
              v-model="tableOptions.search"

              :show-filters.sync="showFilters"

              :check-filter-toggle="checkFilterToggle"
              class="my-4"
            />
            <v-data-table
              v-model="selectedProxy"
              hide-default-footer
              show-select
              :mobile-breakpoint="0"
              item-key="id"
              loading-text="Loading proxies ..."
              :loading="loading"
              :items="proxies"
              :server-items-length="total"
              :headers="proxiesHeaders"
              :options.sync="tableOptions"
              :footer-props="{
                'items-per-page-options': pages,
                'show-first-last-page': true,
                'show-current-page': true,
              }"
            >
              <template
                v-slot:top
              >
                <v-layout
                  v-show="showFilters"
                  class="pa-4"
                >
                  <v-row>
                    <v-col cols="3">
                      <v-select
                        v-model="tableOptions.status"
                        label="Status"
                        placeholder="Select status"
                        hide-details
                        clearable
                        :items="statusOptions"
                        class="pa-0 ma-0"
                      ></v-select>
                    </v-col>
                  </v-row>
                </v-layout>
              </template>
              <template v-slot:item.mobile="{ value }">
                <TrueFalse :value="value"></TrueFalse>
              </template>

              <!-- SELECT ROW-->

              <template v-slot:item.value="{ item }">
                <v-skeleton-loader
                  :loading="item.loading"
                  type="table-cell"
                >
                  {{ item.value }}
                </v-skeleton-loader>
              </template>
              <template v-slot:item.status="{ item }">
                <v-skeleton-loader
                  :loading="item.loading"
                  type="table-cell"
                >
                  <v-chip
                    :color="StatusesColorMap[item.status] && StatusesColorMap[item.status].color"
                  >
                    {{ StatusesColorMap[item.status] && StatusesColorMap[item.status].text }}
                  </v-chip>
                </v-skeleton-loader>
              </template>
              <template v-slot:footer="{ props, on }">
                <TableFooter
                  v-bind="props"
                  v-on="on"
                >
                </TableFooter>
              </template>
            </v-data-table>
          </v-stepper-content>
          <v-stepper-content
            class="pt-0"
            :step="3"
          >
            <v-row class="pl-3">
              <v-col>
                <label for="">Do you want to load all  info about your accounts and run Update Info action ?</label>
                <v-checkbox
                  v-model="updateInfo"
                  label="Get account info"
                />
              </v-col>
            </v-row>
            <v-form
              v-if="updateInfo"
              ref="formInfo"
              class="pl-3"
            >
              <ActionsDelay
                v-model="delay"
              ></ActionsDelay>
            </v-form>
          </v-stepper-content>
        </v-stepper-items>
      </v-stepper>
    </v-card-text>

    <v-divider></v-divider>
    <v-card-actions>
      <v-row>
        <v-col
          cols="12"
          lg="12"
        >
          <v-btn
            color="primary"
            :loading="loading"
            :disabled="step===2&&selectedProxy.length===0"
            @click="submit"
          >
            {{ step===3?'Create':'Next' }}
          </v-btn>
          <v-btn
            class="ml-4"
            :disabled="step===1"
            @click="step--"
          >
            Back
          </v-btn>
        </v-col>
        <v-col
          class="d-flex"
          cols="12"
          md="6"
        >
        </v-col>
      </v-row>
    </v-card-actions>
  </v-card>
</template>

<script>
import { ref, computed } from '@vue/composition-api'

import {
  required, isNotEmptyString, stringLengthValidation, maximumValue, maxMultiplyValue, simpleRequired, isMore, integerValidator,
} from '@core/utils/validation'
import ProxyApi from '@core/api/proxy'
import pick from 'lodash/pick'
import { StatusesColorMap } from '@core/constants'
import _cloneDeep from 'lodash/cloneDeep'
import omit from 'lodash/omit'
import SelectProject from '../project/SelectProject.vue'
import SelectTag from './SelectTag.vue'
import TableFooter from '@/components/TableFooter.vue'
import TrueFalse from '@/components/TrueFalse.vue'
import TableSearch from '@/components/TableSearch.vue'
import ActionsDelay from '@/components/ActionsDelay.vue'

export default {
  components: {
    SelectProject, SelectTag, TableFooter, TrueFalse, TableSearch, ActionsDelay,
  },
  props: {
    isProjectDetails: {
      type: Boolean,
      default: false,
    },
    loading: Boolean,
    projectId: {
      type: Number,
      default: null,
    },
  },
  data() {
    return {
      dialog: true,
      tableOptions: {
        search: '',
        status: '',
        sortBy: [],
        sortDesc: [],
        page: 1,
        itemsPerPage: 50,
      },
      pages: [50, 100, 200],
      total: 50,
      showFilters: false,
      statusOptions: [
        { value: 'active', text: 'Active' },
        { value: 'not_active', text: 'Not Active' },
        { value: 'expired', text: 'Expired' },
        { value: 'banned', text: 'Banned' },
        { value: 'rate_limit', text: 'Rate limit' },
      ],
      StatusesColorMap,
      proxiesHeaders: [
        {
          text: 'Name',
          value: 'name',
          sortable: true,
          align: 'start',
        },
        {
          text: 'URL',
          value: 'value',
          sortable: true,
          filterable: true,
          align: 'start',
        },
        {
          text: 'Mobile',
          value: 'mobile',
          sortable: true,
          align: 'start',
        },
        {
          text: 'Status',
          value: 'status',
          sortable: true,
          align: 'start',
        },
        {
          text: 'Expired At',
          value: 'expired_at',
          sortable: true,
          align: 'start',
        },
      ],
    }
  },
  watch: {
    total() {
      this.tableOptions.page = 1
    },
    tableOptions: {
      handler(value) {
        const query = pick(value, ['sortBy', 'page', 'search', 'status'])

        query.limit = value.itemsPerPage

        if (value.sortBy?.length) {
          [query.sortBy] = value.sortBy
        }

        if (value.sortDesc?.length) {
          [query.sortDesc] = value.sortDesc
        }

        Object.keys(query).forEach(key => {
          if (!query[key] && key !== 'sortDesc') {
            delete query[key]
          }
        })

        this.getProxies(query)
      },
      deep: true,
    },
  },
  methods: {
    checkFilterToggle() {
      const filters = _cloneDeep(this.tableOptions)
      const tableKeys = ['search', 'sortBy', 'sortDesc', 'page', 'itemsPerPage', 'groupBy', 'groupDesc', 'multiSort', 'mustSort']
      let filtersStatus = false
      Object.entries(filters).forEach(([key, value]) => {
        if (tableKeys.includes(key)) {
          delete filters[key]
        } else if (!!value !== false) {
          filtersStatus = true
        }
      })

      return filtersStatus
    },
  },
  setup(props, { emit }) {
    const form = ref(null)
    const formInfo = ref(null)
    const validate = () => form.value.validate()
    const reset = () => {
      form.value.reset()
    }
    const seller = ref('')
    const type = ref(null)
    const proxies = ref([])
    const project = ref(null)
    const tags = ref([])
    const stringValues = ref('')
    const step = ref(1)
    const selectedProxy = ref([])
    const updateInfo = ref(true)
    const delay = ref({
      account_delay: 15,
      batch_delay: 1,
      batch_size: 5,
    })
    const getProxies = async query => {
      const { data } = await ProxyApi.fetchProxies(query)
      proxies.value = data.items
    }

    const proxyId = computed(() => selectedProxy.value.map(el => el.id))

    const calculatedOptions = computed(() => stringValues.value
      .split('\n')
      .filter(el => el)
      .map(el => {
        let login; let password; let
          token
        const strParams = el.split(':')
        if (strParams.length === 3) {
          [login, password, token] = strParams
        } else {
          [token] = strParams
        }

        return { login, password, token }
      }))

    const submit = async () => {
      if (validate() && step.value === 1) {
        step.value = 2
        getProxies()

        return
      }
      if (step.value === 2) {
        step.value = 3

        return
      }
      if (step.value === 3) {
        const data = {
          credentials: calculatedOptions.value,
          type: type.value,
          seller: seller.value,
          tags: tags.value,
          proxy: proxyId.value,
          project_ids: props.isProjectDetails ? [props.projectId] : project.value,
          update_info: updateInfo.value,
          account_delay: delay.value.account_delay,
          batch_delay: delay.value.batch_delay,
          batch_size: delay.value.batch_size,
        }
        if (formInfo.value?.validate() && updateInfo.value) {
          emit('submit', data)
        } else if (!updateInfo.value) {
          emit('submit', omit(data, ['account_delay', 'batch_delay', 'batch_size']))
        }
      }
    }

    const types = [{
      text: 'White List',
      value: 'white list',
    }, {
      text: 'Giveaway',
      value: 'giveaway',
    },

    // {
    //   text: 'Referral',
    //   value: 'referral',
    // }
    ]

    const headers = [{
      text: 'Login',
      value: 'login',
    }, {
      text: 'Password',
      value: 'password',
    }, {
      text: 'Token',
      value: 'token',
    }]

    return {
      step,
      form,
      stringValues,
      seller,
      type,
      tags,
      types,
      proxies,
      project,
      calculatedOptions,
      headers,
      validate,
      reset,
      required,
      isNotEmptyString,
      submit,
      getProxies,
      selectedProxy,
      proxyId,
      stringLengthValidation,
      updateInfo,
      maximumValue,
      maxMultiplyValue,
      simpleRequired,
      isMore,
      integerValidator,
      delay,
      formInfo,
    }
  },
}
</script>
