<template>
  <v-dialog
    v-model="isOpen"
    width="90vw"
    persistent
    @click:outside="tryClose"
  >
    <v-card>
      <v-card-title>
        Fast join
        <v-spacer />
        <v-btn
          icon
          @click="tryClose"
        >
          <v-icon>{{ icons.mdiClose }}</v-icon>
        </v-btn>
      </v-card-title>
      <v-card-text>
        <v-stepper v-model="step">
          <v-stepper-header>
            <v-stepper-step
              :complete="step > 1"
              step="1"
            >
              Invite link
            </v-stepper-step>

            <v-divider></v-divider>

            <v-stepper-step
              :complete="step > 2"
              step="2"
            >
              Delay
            </v-stepper-step>
          </v-stepper-header>
          <v-stepper-items>
            <v-stepper-content step="1">
              <v-card>
                <v-form ref="form1">
                  <v-row>
                    <v-col>
                      <label for="invite_link">
                        Invite link
                      </label>

                      <v-text-field
                        id="invite_link"
                        v-model="formData.invite_link"
                        :rules="[isNotEmptyString,urlValidator]"
                      />
                    </v-col>
                  </v-row>
                </v-form>
              </v-card>
            </v-stepper-content>
            <v-stepper-content step="2">
              <v-form ref="form2">
                <ActionsDelay
                  v-model="formData"
                ></ActionsDelay>
              </v-form>
            </v-stepper-content>
          </v-stepper-items>
        </v-stepper>
        <v-form ref="form">
        </v-form>
      </v-card-text>
      <v-divider />

      <v-card-actions>
        <v-btn
          :disabled="step===1"
          @click="step--"
        >
          Back
        </v-btn>
        <v-spacer></v-spacer>
        <v-btn
          color="primary"
          :loading="loading"
          @click="submit"
        >
          {{ step===2?'Join':'Next' }}
        </v-btn>
        <v-btn
          :disabled="loading"
          @click="tryClose"
        >
          Cancel
        </v-btn>
      </v-card-actions>
    </v-card>
    <ConfirmDialog
      ref="updateConfirm"
      :width="400"
      :z-index="99"
    >
      <template v-slot:default="{agree, cancel}">
        <v-card>
          <v-card-title>Confirm update cancel</v-card-title>
          <v-card-text>
            You have unsaved changes, you wanna leave
          </v-card-text>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn
              outlined
              @click="agree"
            >
              Agree
            </v-btn>
            <v-btn
              color="primary"
              @click="cancel"
            >
              Cancel
            </v-btn>
          </v-card-actions>
        </v-card>
      </template>
    </ConfirmDialog>
  </v-dialog>
</template>

<script>
import { mdiClose } from '@mdi/js'

import DiscordApi from '@core/api/discord'
import _cloneDeep from 'lodash/cloneDeep'
import isEqual from 'lodash/isEqual'
import {
  simpleRequired, isNotEmptyString, stringLengthValidation, maximumValue, isMore, maxMultiplyValue, integerValidator, urlValidator,
} from '@core/utils/validation'
import ConfirmDialog from '../../../ConfirmDialog.vue'
import ActionsDelay from '@/components/ActionsDelay.vue'

const DEFAULT_DATA = {
  invite_link: '',
  account_delay: 15,
  batch_delay: 1,
  batch_size: 5,
  useFilter: false,
}

export default {
  components: { ConfirmDialog, ActionsDelay },
  props: {
    selectedOptions: {
      type: Object,
      default: () => ({}),
    },
  },
  data() {
    return {
      icons: {
        mdiClose,
      },
      isOpen: false,
      loading: false,
      step: 1,
      initFormData: DEFAULT_DATA,
      formData: DEFAULT_DATA,
    }
  },
  computed: {
    isChanged() {
      return !isEqual(this.initFormData, this.formData)
    },
  },
  methods: {
    isMore,
    simpleRequired,
    isNotEmptyString,
    stringLengthValidation,
    maximumValue,
    maxMultiplyValue,
    integerValidator,
    urlValidator,
    open() {
      this.isOpen = true
      this.formData = _cloneDeep(this.initFormData)
    },
    async submit() {
      if (this.$refs.form1.validate() && this.step === 1) {
        this.step = 2

        return
      }
      if (this.$refs.form2.validate() && this.step === 2) {
        try {
          this.loading = true
          await DiscordApi.Project.fastJoin({
            ...this.selectedOptions,
            ...this.formData,
          })
          this.reset()
          this.$message.success('Account parsed successfully')
        } catch (err) {
          this.$message.error(err)
        } finally {
          this.loading = false
        }
      }
    },
    reset() {
      this.isOpen = false
    },
    async tryClose() {
      if (this.isChanged && await this.$refs.updateConfirm.open()) {
        this.reset()
      } else this.reset()
    },
  },
}
</script>
