<template>
  <v-dialog
    v-model="isOpen"
    width="90vw"
    persistent
    @click:outside="tryClose"
  >
    <v-card>
      <v-card-title>
        Edit account
        <v-spacer />
        <v-btn
          icon
          @click="tryClose"
        >
          <v-icon>{{ icons.mdiClose }}</v-icon>
        </v-btn>
      </v-card-title>

      <v-card-text>
        <EditAccountForm
          v-if="account"
          ref="form"
          :hide-project="hideProject"
          :project-id="projectId"
          :account="account"
          :account-projects="accountProjects"
          :account-proxies="accountProxies"
        />
      </v-card-text>

      <v-divider />

      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn
          color="primary"
          @click="submit"
        >
          Update
        </v-btn>
        <v-btn @click="tryClose">
          Cancel
        </v-btn>
      </v-card-actions>
    </v-card>

    <!--
      CONFIRM CANCEL UPDATE
    -->
    <ConfirmDialog
      ref="updateConfirm"
      :width="400"
      :z-index="99"
    >
      <template v-slot:default="{agree, cancel}">
        <v-card>
          <v-card-title>Confirm update cancel</v-card-title>
          <v-card-text>
            You have unsaved changes, you wanna leave
          </v-card-text>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn
              outlined
              @click="agree"
            >
              Agree
            </v-btn>
            <v-btn
              color="primary"
              @click="cancel"
            >
              Cancel
            </v-btn>
          </v-card-actions>
        </v-card>
      </template>
    </ConfirmDialog>
  </v-dialog>
</template>

<script>
import { mdiClose } from '@mdi/js'

import cloneDeep from 'lodash/cloneDeep'
import isEqual from 'lodash/isEqual'

import ConfirmDialog from '../../ConfirmDialog.vue'
import EditAccountForm from './EditForm.vue'

const DEFAULT_NICKNAME = {
}

export default {
  components: { ConfirmDialog, EditAccountForm },
  props: {
    hideProject: Boolean,
    projectId: {
      type: Number,
      default: null,
    },
  },
  data: () => ({
    icons: {
      mdiClose,
    },
    isOpen: false,
    initAccount: DEFAULT_NICKNAME,
    account: DEFAULT_NICKNAME,
  }),
  computed: {
    isChanged() {
      return !isEqual(this.initAccount, this.account)
    },
    accountProjects() {
      return this.account.projects && this.account.projects.map(el => ({ text: el.name, value: el.id }))
    },
    accountProxies() {
      return this.account.proxy && {
        text: this.account.proxy.name,
        value: this.account.proxy.id,
        description: this.account.proxy.value,
      }
    },
  },
  methods: {
    open(account) {
      this.isOpen = true
      this.initAccount = cloneDeep(account)
      this.account = cloneDeep(account)
    },
    async submit() {
      if (this.$refs.form.validate()) {
        try {
          const data = await this.$refs.form.submit()
          this.$emit('update', data)
          this.reset()
          this.$message.success('Account updated successfully')
        } catch (err) {
          this.reset()
          this.$message.error(err)
        }
      }
    },
    reset() {
      this.isOpen = false
      this.account = null
    },
    async tryClose() {
      if (this.isChanged && await this.$refs.updateConfirm.open()) {
        this.reset()
      } else this.reset()
    },
  },
}
</script>

<style>

</style>
