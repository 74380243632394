<template>
  <v-dialog
    v-model="isOpen"
    width="90vw"
    persistent
    @click:outside="tryClose"
  >
    <v-card>
      <v-card-title>
        Change Avatar
        <v-spacer />
        <v-btn
          icon
          @click="tryClose"
        >
          <v-icon>{{ icons.mdiClose }}</v-icon>
        </v-btn>
      </v-card-title>

      <v-card-text>
        <v-form ref="form">
          <v-tabs
            v-model="formMode"
            grow
          >
            <v-tab
              :disabled="loading"
              @change="resetForm"
            >
              List
            </v-tab>
            <v-tab
              :disabled="loading"
              @change="resetForm"
            >
              Manual
            </v-tab>
            <v-tab
              :disabled="loading"
              @change="resetForm"
            >
              Account Id
            </v-tab>
          </v-tabs>
          <v-tabs-items
            v-model="formMode"
            class="my-4"
          >
            <v-tab-item>
              <v-row>
                <v-col>
                  <label for="urls">
                    Image List
                  </label>

                  <SelectAvatar
                    id="urls"
                    v-model="formData.avatar_list_id"
                    :rules="[formMode==0?simpleRequired:true]"
                  />
                </v-col>
              </v-row>
            </v-tab-item>
            <v-tab-item>
              <v-row>
                <v-col>
                  <label for="urls">
                    URLs
                  </label>

                  <v-textarea
                    id="urls"
                    v-model="formData.urls"
                    :rules="[(images && images.length)||formMode!==1 ? true : imageValidation]"
                    @blur="blurEventHandler"
                  />
                </v-col>
              </v-row>
              <v-row>
                <v-col>
                  <label for="urls">
                    Images
                  </label>

                  <DragAndDrop
                    id="urls"
                    ref="dragAndDrop"
                    v-model="images"
                    :validated="formMode==1?validated:true"
                    :urls="formData.urls"
                    @delete="(index)=>urlDelete(index)"
                  />
                </v-col>
              </v-row>
            </v-tab-item>
            <v-tab-item>
              <v-row>
                <v-col>
                  <label for="user_id">
                    Account Id
                  </label>

                  <v-text-field
                    id="user_id"
                    v-model.number="formData.user_id"
                    type="number"
                  />
                </v-col>
              </v-row>
            </v-tab-item>
          </v-tabs-items>
          <ActionsDelay
            v-model="formData"
          ></ActionsDelay>
        </v-form>
        <v-alert
          border="bottom"
          colored-border
          type="warning"
          elevation="2"
        >
          Gif format available only for account with Nitro
        </v-alert>
      </v-card-text>

      <v-divider />

      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn
          color="primary"
          :loading="loading"
          @click="submit"
        >
          Update
        </v-btn>
        <v-btn
          :disabled="loading"
          @click="tryClose"
        >
          Cancel
        </v-btn>
      </v-card-actions>
    </v-card>

    <!--
      CONFIRM CANCEL UPDATE
    -->
    <ConfirmDialog
      ref="updateConfirm"
      :width="400"
      :z-index="99"
    >
      <template v-slot:default="{agree, cancel}">
        <v-card>
          <v-card-title>Confirm update cancel</v-card-title>
          <v-card-text>
            You have unsaved changes, you wanna leave
          </v-card-text>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn
              outlined
              @click="agree"
            >
              Agree
            </v-btn>
            <v-btn
              color="primary"
              @click="cancel"
            >
              Cancel
            </v-btn>
          </v-card-actions>
        </v-card>
      </template>
    </ConfirmDialog>
  </v-dialog>
</template>

<script>
import { mdiClose } from '@mdi/js'
import _cloneDeep from 'lodash/cloneDeep'
import isEqual from 'lodash/isEqual'
import pickBy from 'lodash/pickBy'
import {
  simpleRequired, isNotEmptyString, maximumValue, maxMultiplyValue, isMore, integerValidator,
} from '@core/utils/validation'

import DiscordApi from '@core/api/discord'
import ActionsDelay from '@/components/ActionsDelay.vue'
import ConfirmDialog from '../../../ConfirmDialog.vue'
import DragAndDrop from '@/components/DragAndDrop.vue'
import SelectAvatar from '../../profile-data/avatar/SelectAvatar.vue'

const DEFAULT_DATA = {
  account_delay: 15,
  batch_delay: 1,
  batch_size: 5,
  urls: '',
  value: [],
  avatar_list_id: null,
  user_id: '',
  useFilter: false,
}

export default {
  components: {
    ConfirmDialog, DragAndDrop, SelectAvatar, ActionsDelay,
  },
  props: {
    selectedOptions: {
      type: Object,
      default: () => ({}),
    },
  },
  data: () => ({
    icons: {
      mdiClose,
    },
    loading: false,
    isOpen: false,
    images: [],
    accounts: [],
    initFormData: DEFAULT_DATA,
    formData: DEFAULT_DATA,
    validated: true,
    isEmpty: true,
    formMode: 0,
  }),
  computed: {
    isChanged() {
      return !isEqual(this.initFormData, this.formData)
    },
  },
  watch: {
    images() {
      if (this.images && this.images.length) {
        this.validated = true
      }
    },
  },
  methods: {
    isNotEmptyString,
    maximumValue,
    maxMultiplyValue,
    simpleRequired,
    isMore,
    integerValidator,
    open(accounts) {
      this.validated = null
      this.isOpen = true
      this.accounts = accounts.map(el => el.id)
      this.formData = _cloneDeep(this.initFormData)
    },
    async submit() {
      if (this.$refs.form.validate()) {
        try {
          this.loading = true
          const uploadedImages = await this.$refs.dragAndDrop?.uploadImages()

          if (uploadedImages && uploadedImages.length > 0) {
            this.formData.value = uploadedImages.map(({ url }) => ({ url }))
          }

          await DiscordApi.Account.changeAvatar({
            ...this.selectedOptions,
            ...pickBy(this.formData, value => !!value),
            urls: this.formData.urls && this.formData.urls.split('\n').filter(el => el),
            account_delay: +this.formData.account_delay,
            batch_delay: +this.formData.batch_delay,
            batch_size: +this.formData.batch_size,
          })
          this.reset()
          this.$message.success('Avatar changed successfully')
        } catch (error) {
          this.$message.error(error)
        } finally {
          this.loading = false
          this.reset()
        }
      }
    },
    reset() {
      this.isOpen = false
      this.images = []
      this.formData = _cloneDeep(this.initFormData)
    },
    async tryClose() {
      if (this.isChanged && await this.$refs.updateConfirm.open()) {
        this.reset()
      } else this.reset()
    },
    imageValidation(value) {
      if (value && value.length && value.trim()) {
        this.validated = true
        this.isEmpty = false

        return true
      }
      if (this.validated === null) {
        this.validated = true
      } else {
        this.validated = false
      }

      return 'This field is required or drop your images in dropdown'
    },
    blurEventHandler() {
      if (this.isEmpty) {
        this.validated = false
      }
    },
    resetForm() {
      const delay = {
        account_delay: this.formData.account_delay,
        batch_delay: this.formData.batch_delay,
        batch_size: this.formData.batch_size,
      }
      this.formData = { ..._cloneDeep(this.initFormData), ...delay }
    },
    urlDelete(index) {
      const imageUrls = this.formData.urls.trim().split('\n')
      imageUrls.splice(index, 1)
      this.formData.urls = imageUrls.join('\n')
    },
  },
}
</script>

<style scoped>
label span{
  font-size: 0.7rem;
}
</style>
