<template>
  <v-form ref="form">
    <v-row>
      <v-col>
        <label for="login">Token</label>
      </v-col>
      <v-col cols="12">
        <v-textarea
          id="login"
          v-model="token"
          outlined
          dense
          rows="2"
          hide-details="auto"
          placeholder="Enter token"
          :disabled="loading"
          :rules="[isNotEmptyString]"
        ></v-textarea>
      </v-col>
      <v-col
        cols="12"
        md="6"
      >
        <v-col>
          <label for="login">Login</label>
        </v-col>
        <v-col>
          <v-text-field
            id="login"
            v-model="login"
            outlined
            dense
            hide-details="auto"
            placeholder="Enter login"
            :disabled="loading"
          ></v-text-field>
        </v-col>
        <v-col>
          <label for="password">Password</label>
        </v-col>
        <v-col>
          <v-text-field
            id="password"
            v-model="password"
            outlined
            dense
            hide-details="auto"
            placeholder="Enter password"
            :disabled="loading"
          ></v-text-field>
        </v-col>
        <v-col>
          <label for="seller">Seller</label>
        </v-col>
        <v-col>
          <v-text-field
            id="seller"
            v-model="seller"
            outlined
            dense
            hide-details="auto"
            placeholder="Enter seller"
            :disabled="loading"
            :rules="[stringLengthValidation(null,100)]"
          ></v-text-field>
        </v-col>
        <v-col>
          <label for="type">Type</label>
        </v-col>
        <v-col>
          <v-select
            id="type"
            v-model="type"
            outlined
            dense
            multiple
            hide-details="auto"
            placeholder="Select type(s)"
            :items="types"
            :disabled="loading"
          ></v-select>
        </v-col>
        <v-col>
          <label for="tags">Tags</label>
        </v-col>
        <v-col>
          <SelectTag
            v-model="tags"
            multiple
          />
        </v-col>
      </v-col>
      <v-col
        cols="12"
        md="6"
      >
        <v-col>
          <label for="status">Status</label>
        </v-col>
        <v-col>
          <v-select
            id="status"
            v-model="status"
            outlined
            dense
            hide-details="auto"
            placeholder="Select status"
            :items="statuses"
            :disabled="loading"
            :rules="[required]"
          ></v-select>
        </v-col>
        <template v-if="!hideProject">
          <v-col>
            <label for="type">Project</label>
          </v-col>
          <v-col>
            <SelectProject
              v-model="project"
              multiple
              :account-projects="accountProjects"
            />
          </v-col>
        </template>
        <v-col>
          <label for="type">Proxy</label>
        </v-col>
        <v-col>
          <SelectProxy
            v-model="proxy"
            :rules="[isTrue]"
            :account-proxies="accountProxies"
          />
        </v-col>
      </v-col>
    </v-row>
  </v-form>
</template>

<script>
import { ref } from '@vue/composition-api'

import {
  required, isNotEmptyString, isTrue, stringLengthValidation,
} from '@core/utils/validation'
import SelectProxy from '@/components/proxy/SelectProxy.vue'
import SelectProject from '../project/SelectProject.vue'
import SelectTag from './SelectTag.vue'

export default {
  components: { SelectProxy, SelectProject, SelectTag },
  props: {
    loading: Boolean,
    account: {
      type: Object,
      default: () => ({}),
    },
    hideProject: Boolean,
    projectId: {
      type: Number,
      default: null,
    },
    accountProjects: {
      type: Array,
      default: null,
    },
    accountProxies: {
      type: Object,
      default: null,
    },
  },
  setup({ account, hideProject, projectId }) {
    const form = ref(null)
    const validate = () => form.value.validate()
    const reset = () => {
      form.value.reset()
    }
    const seller = ref(account.seller)
    const type = ref(account.type)
    const proxy = ref(account.proxy?.id)
    const project = !hideProject ? ref(account.projects?.map((p => p.id))) : [projectId]
    const status = ref(account.status)
    const login = ref(account.login)
    const password = ref(account.password)
    const token = ref(account.token)
    const tags = ref(account.tags)

    const submit = async () => ({
      id: account.id,
      login: login.value,
      password: password.value,
      type: type.value,
      tags: tags.value,
      status: status.value,
      seller: seller.value,
      proxy: proxy.value,
      token: token.value,
      project_ids: !hideProject ? project.value : project,
    })

    const types = [{
      text: 'White List',
      value: 'white list',
    }, {
      text: 'Giveaway',
      value: 'giveaway',
    },

    // {
    //   text: 'Referral',
    //   value: 'referral',
    // },
    ]

    const statuses = [{
      text: 'Active',
      value: 'active',
    }, {
      text: 'Banned',
      value: 'banned',
    }, {
      text: 'Locked',
      value: 'locked',
    }]

    return {
      isTrue,
      form,
      seller,
      login,
      token,
      password,
      type,
      tags,
      types,
      status,
      statuses,
      proxy,
      project,
      validate,
      reset,
      required,
      isNotEmptyString,
      submit,
      stringLengthValidation,
    }
  },
}
</script>
