<template>
  <v-combobox
    v-model="localValue"
    :loading="loading"
    :disabled="disabled"
    :items="localItems"
    :search-input.sync="search"
    cache-items
    :multiple="multiple"
    :rules="rules"
    hide-no-data
    chips
    hide-details
    hide-selected
    label="Select tag(s)"
    clearable
    deletable-chips
    class="mt-0 pt-0"
    @focus="tryFetchTag"
  >
  </v-combobox>
</template>

<script>
import DiscordApi from '@core/api/discord'

export default {
  props: {
    disabled: Boolean,
    multiple: {
      type: Boolean,
      default: false,
    },
    value: {
      type: [Number, Array],
      default: null,
    },
    rules: {
      type: Array,
      default: () => [],
    },
  },
  data() {
    return {
      loading: false,
      items: [],
      search: null,
    }
  },
  computed: {
    localItems() {
      return this.items
    },
    localValue: {
      get() {
        return this.value
      },
      set(value) {
        this.$emit('input', value)
      },
    },
  },
  watch: {
    search(val) {
      this.tryFetchTag(val)
    },
  },
  mounted() {
    this.tryFetchTag()
  },
  methods: {
    async tryFetchTag(search = this.search) {
      this.loading = true
      await DiscordApi.Account.fetchTags({ search }).then(({ data }) => {
        this.items = data.tags.map(({ label }) => label)
      })
      this.loading = false
    },
  },
}
</script>

<style>

</style>
